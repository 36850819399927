import React from "react";
import { Form, Input, Button, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Dropdwn from "./Dropdwn"

import "./SeachCars.scss";

const SearchCars = () => {
  const [form] = Form.useForm();

  return (
    <div className="b-form">
      <h1>Find The Right Luxury Car.</h1>
      <Form
        layout={"inline"}
        form={form}
        initialValues={{
          layout: "inline",
        }}
     className="form" >
        <Select
            showSearch
            placeholder="Enter Location"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            
            className="select" style={{borderRadius:"10px"}} >
            <Select.Option value="1">Bengaluru</Select.Option>
            <Select.Option value="6">Chennai</Select.Option>
            <Select.Option value="7">Hyderabad</Select.Option>
            <Select.Option value="13">Mumbai</Select.Option>
            <Select.Option value="14">Cochin</Select.Option>
            <Select.Option value="15">Goa</Select.Option>
            <Select.Option value="20">Gurgoan</Select.Option>
            {/* <Select.Option value="2">Mysore</Select.Option> */}
            {/* <Select.Option value="3">Hassan</Select.Option> */}
            {/* <Select.Option value="4">Davanagere</Select.Option> */}
            {/* <Select.Option value="5">Tumkuru</Select.Option> */}
            {/* <Select.Option value="8">Vizag</Select.Option>
            <Select.Option value="9">Tirupati</Select.Option>
            <Select.Option value="10">Madurai</Select.Option>
            <Select.Option value="11">Trichy</Select.Option>
            <Select.Option value="12">Coimbatore</Select.Option> */}
            {/* <Select.Option value="16">Ahmedabad</Select.Option>
            <Select.Option value="17">Baroda</Select.Option>
            <Select.Option value="18">Bhopal</Select.Option>
            <Select.Option value="19">Delhi</Select.Option>  
            <Select.Option value="21">Kolkata</Select.Option>
            <Select.Option value="22">Shimla</Select.Option>
            <Select.Option value="23">Jammu & Kashmir</Select.Option> */}
          </Select>
     
        <Dropdwn />
        <Select
          showSearch
          placeholder="Select Price"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          className="select"
        >
          <Select.Option value="1">₹ 5 Lacs   - ₹ 10 Lacs</Select.Option>
          <Select.Option value="2">₹ 10 Lacs  - ₹ 50 Lacs</Select.Option>
          <Select.Option value="3">₹ 50 Lacs  - ₹ 1 Crore</Select.Option>
          <Select.Option value="4">₹ 1 Crore  - ₹ 5 Crore</Select.Option>
          <Select.Option value="5">₹ 5 Crore  - ₹ 10 Crore</Select.Option>
          <Select.Option value="6">₹ 10 Crore - ₹ 15 Crore</Select.Option>
          <Select.Option value="7">₹ 15 Crore - ₹ 20 Crore</Select.Option>
        </Select>
        <Button
          type="Submit"
          color="s-btn"
          style={{
            backgroundColor: "#ff5421",
            color: "white",
            height: "45px",
            width: "100px",
            borderRadius: "5px",
          }}
          icon={<SearchOutlined />}
        >
          Search
        </Button>
      </Form>
    </div>
  );
};

export default SearchCars;
