import React, { Component } from 'react';
import { Row, Col } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import ModalBoxFooter from '../../../Components/ModalBox/ModalBoxFooter';
import { uploadCarImages } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import MultipleImageBox from '../../../Components/ImageBox/MultipleImageBox';

const STATUS_OPTIONS = [
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'InActive',
        label: 'InActive',
    },
]

class ManagePictures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoader: false,

            exterior: props.carData ? (props.carData.images ? props.carData.images.filter(val => val.imageType === 'exterior') : []) : [],
            interior: props.carData ? (props.carData.images ? props.carData.images.filter(val => val.imageType === 'interior') : []) : [],
        }
    }

    onChangeImage = (value, type) => {
        const images = value.filter(val => !val.path);
        const imageData = new FormData();
        images.map(img => imageData.append('images', img))
        imageData.append('imageType', type)
        uploadCarImages(this.props.carId, imageData).then((car) => {
            if (car) {
                this.setState({ [type]: car.images.filter(val => val.imageType === type) })
            }
        })
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onCar = () => {
        this.props.onChangeIndex(3)
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <MultipleImageBox label={'Exterior'} refs={(ref) => this.exterior = ref} id={'exterior'} value={this.state.exterior} isSubmit={this.state.isSubmit} onChange={this.onChangeImage} minHeight={150} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <MultipleImageBox label={'Interior'} refs={(ref) => this.interior = ref} id={'interior'} value={this.state.interior} isSubmit={this.state.isSubmit} onChange={this.onChangeImage} minHeight={150} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ButtonBox type="primary" onClick={() => this.onCar()}>
                            Next
                </ButtonBox>
                        <ButtonBox type="secondary" onClick={() => this.props.onChangeIndex(1)}>
                            Previous
                </ButtonBox>
                    </div>
                </Col>
            </Row>
        );
    }
};

export default ManagePictures; 
