import React from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';
import { getLocalStorage, setLocalStorage } from '../../../Util/Util';
import InputBox from '../../../Components/InputBox/InputBox';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import './AdminLogin.scss';
// import loginImage from '../../assets/images/registration-1.png';
import { login } from '../../../Actions/UserAction';

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            isSubmit: false
        }
    }

    componentDidMount() {
        const currentUser = getLocalStorage('AUTHENTICATED');
        if (currentUser) {
            this.props.history.push('/app/dashoard')
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    onLogin = () => {
        const { email, password } = this.state;
        const validateFields = ['email', 'password'];
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
            return true
        })
        if (flag) {
            this.props.dispatch(login({ email, password })).then((userData) => {
                if (userData) {
                    setLocalStorage('AUTHENTICATED', userData.token)
                    this.props.history.push('/app/admin-dashboard')
                }
            })
        } else {
            this.setState({ isSubmit: true });
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* <Header /> */}
                <section className="login-register">
                    <div class="container-fluid">
                        <Row justify="center">
                            <Col span={20}>
                                <Row justify="left" gutter={[20, 20]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="left-side-image">
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="login-register-fields">
                                            <h1>Login to SUV Selct</h1>
                                            <form>
                                                <div class="form-fields">
                                                    <InputBox refs={(ref) => this.email = ref} placeholder="Email ID" id="email" value={this.state.email} isSubmit={this.state.isSubmit} prefix={'flaticon-user-2'} onChangeText={this.onChangeText} />
                                                </div>
                                                <div class="form-fields">
                                                    <InputBox refs={(ref) => this.Password = ref} placeholder="Password" id="password" value={this.state.password} isSubmit={this.state.isSubmit} prefix={'flaticon-locked'} type={'password'} onChangeText={this.onChangeText} />
                                                </div>
                                                <div class="form-fields">
                                                    <ButtonBox onClick={this.onLogin} type="primary">Login</ButtonBox>
                                                </div>
                                            </form>

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>

                {/* <Footer /> */}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.users.userInfo,
    };
}

export default connect(mapStateToProps)(Login);
