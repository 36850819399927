import React from 'react';
import './Header.scss';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import { Row, Dropdown, Col, Menu, Input } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import { deleteLocalStorage } from '../../Util/Util';

import profileimage from '../../assets/images/profile.jpg';
import mainlogo from '../../assets/images/suv-select.png';

const masterdatamenu = (
    <Menu>
        <Menu.Item key="cities">
            <Link to="/app/cities">Cities</Link>
        </Menu.Item>
        <Menu.Item key="brands">
            <Link to="/app/brands">Brands</Link>
        </Menu.Item>
    </Menu>
);
const carsmenu = (
    <Menu>
        <Menu.Item key="0">
            <Link to="/app/cars">Cars</Link>
        </Menu.Item>
    </Menu>
);
export default class Header extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    profilecontent = () => {
        return <Menu>
            <Menu.Item key="0">
                <Link><i className="flaticon-user-2" /> My Account</Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link onClick={() => this.onRedirect('/')}><i className="flaticon-logout" />  Logout</Link>
            </Menu.Item>
        </Menu>
    }

    onRedirect = (path) => {
        if (path === '/') {
            deleteLocalStorage('AUTHENTICATED');
            this.props.history.push(path)
        } else {
            this.props.history.push(path)
        }
    }

    render() {
        return (
            <div className="main-hearder">
                <Row>
                    <Col xs={{ span: 12, order: 1 }} sm={{ span: 4, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 3, order: 1 }}>
                        <div className="logo">
                            <Link to="/app/dashboard"><img src={mainlogo} alt="SUV Selct" /> </Link>
                        </div>
                    </Col>

                    <Col xs={{ span: 12, order: 1 }} sm={{ span: 20, order: 1 }} md={{ span: 21, order: 1 }} lg={{ span: 21, order: 1 }}>
                        <div className="container-menu">
                            <Row>
                                <Col xs={{ span: 11, order: 1 }} sm={{ span: 11, order: 1 }} md={{ span: 11, order: 1 }} lg={{ span: 11, order: 1 }}>
                                    <div className="navigation">
                                        <Menu mode="horizontal">
                                            <Menu.Item key="1">
                                                <Dropdown overlay={masterdatamenu} trigger={['click']} arrow>
                                                    <Link className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                        Master data <CaretDownOutlined />
                                                    </Link>
                                                </Dropdown>
                                            </Menu.Item>
                                            <Menu.Item key="2">
                                                <Dropdown overlay={carsmenu} trigger={['click']} arrow>
                                                    <Link className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                        Cars <CaretDownOutlined />
                                                    </Link>
                                                </Dropdown>
                                            </Menu.Item>
                                        </Menu>
                                    </div>
                                </Col>

                                <Col xs={{ span: 5, order: 2 }} sm={{ span: 5, order: 2 }} md={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }}>
                                    <div className="search">
                                        <Input size="large" placeholder="Search here.." prefix={<SearchOutlined />} bordered={false} />
                                    </div>
                                </Col>

                                <Col xs={{ span: 8, order: 3 }} sm={{ span: 8, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 8, order: 3 }}>
                                    <div className="right-profile-menu">
                                        <ul>
                                            <li><Link className="notification"><i className="flaticon-notification" /><span>3</span></Link></li>
                                            <li>
                                                <Dropdown overlay={this.profilecontent()} trigger={['click']} arrow>
                                                    <Link className="ant-dropdown-link profile-image" onClick={e => e.preventDefault()}>
                                                        <img src={profileimage} alt="Profile Image" />
                                                    </Link>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}