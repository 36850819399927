import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from "react-router-dom";
import CommonRoutes from "./CommonRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

class Routes extends React.Component {
  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || "/"}>
        <ScrollToTop>
          <Switch>
            <Route path="/app" component={AuthenticatedRoutes} />
            <Route path="/" component={CommonRoutes} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default Routes;
