import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import { getCarByBrandAndName, getCarVersions } from '../../Actions/UserAction';
import { getImageUrl } from '../../Actions/ActionUrl';
import { convertPrice } from '../../Util/Util';
import { FileImageOutlined, BgColorsOutlined, VideoCameraOutlined, DatabaseOutlined } from '@ant-design/icons';
import Gallery from 'react-grid-gallery';

const Featurecarssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};


export default class CarColors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car: false,
            colors: [],
            selectedColor: false
        }
        this.getCarData();
    }

    getCarData = () => {
        const brand = this.props.match.params.brand;
        const name = this.props.match.params.name;
        getCarByBrandAndName(brand, name).then((car) => {
            if (car) {
                this.setState({ car, colors: car.colors, selectedColor: car.colors[0] });
            }
        })
    }

    render() {
        const { car, selectedColor } = this.state;
        const carName = car ? `${car.brandName} ${car.name}` : ''
        return (
            <div>
                {car && selectedColor &&
                    <div className="car-detail">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div style={{ paddingBottom: 15 }}>
                                    <div className="boldtext">{carName} Colors</div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="container">
                                <div className="image-holder">
                                    <img style={{ width: '100%' }} src={getImageUrl(selectedColor.image)} alt="Car Title" />
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <center>
                                    {this.state.colors.map((colo) => {
                                        return <div onClick={() => this.setState({ selectedColor: colo })} style={{ display: 'inline-table', margin: 5, cursor: 'pointer' }}>{colo.colors.map((co) => <div style={{ background: co, width: `${Math.round(40 / colo.colors.length)}px`, height: 40, color: '#fff', display: 'inline-table' }}></div>)}</div>
                                    })}
                                    <div>{selectedColor.name}</div>
                                </center>
                            </Col>
                        </Row>
                    </div>}
            </div>
        );
    }
}