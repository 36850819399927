import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import { getCarByBrandAndName, getCarVersions } from '../../Actions/UserAction';
import { getImageUrl } from '../../Actions/ActionUrl';
import { convertPrice } from '../../Util/Util';
import { FileImageOutlined, BgColorsOutlined, VideoCameraOutlined, DatabaseOutlined } from '@ant-design/icons';
import Gallery from 'react-grid-gallery';

const Featurecarssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};


export default class CarImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car: false,
            images: []
        }
        this.getCarData();
    }

    getCarData = () => {
        const brand = this.props.match.params.brand;
        const name = this.props.match.params.name;
        const type = this.props.match.params.type;
        getCarByBrandAndName(brand, name).then((car) => {
            if (car) {
                const images = []
                car.images.map((val, ind) => {
                    val['src'] = getImageUrl(val.path);
                    val['thumbnail'] = getImageUrl(val.path);
                    val['thumbnailWidth'] = 200
                    val['thumbnailHeight'] = 100
                    if (type === "all") {
                        images.push(val)
                    } else if (type === "exterior" && val.imageType === 'exterior') {
                        images.push(val)
                    } else if (type === "interior" && val.imageType === 'interior') {
                        images.push(val)
                    }

                })
                this.setState({ car, images });
            }
        })
    }

    render() {
        const { car } = this.state;
        const carName = car ? `${car.brandName} ${car.name}` : ''
        return (
            <div>
                {car &&
                    <div className="car-detail">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div style={{ paddingBottom: 15 }}>
                                    <div className="boldtext">{carName} Images</div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="container">
                                <Gallery images={this.state.images} />
                            </Col>
                        </Row>
                    </div>}
            </div>
        );
    }
}