import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./Footer.scss";
export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <Row justify="center">
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Quick Links</h4>
                    <ul>
                      <li>
                        <Link to="/aboutus">About us</Link>
                      </li>
                      <li>
                        <Link to="/">Team</Link>
                      </li>
                      <li>
                        <Link to="/">Who We are</Link>
                      </li>
                      <li>
                        <Link to="/">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                {/* <!--Tools and Products ends--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Contact</h4>
                    <ul>
                      <li>
                        <Link to="/">Help & Support</Link>
                      </li>
                      <li>
                        <Link to="/">Partner with us</Link>
                      </li>
                      <li>
                        <Link to="/">Ride with us</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                {/* <!--Helful Links ends--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Legal Informations</h4>
                    <ul>
                      <li>
                        <Link to="/TermsAndConditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/PrivacyPolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/">Cookie Policy</Link>
                      </li>
                      <li>
                        <Link to="/">Offer Terms</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                {/* <!--Helful Links ends--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Follow us</h4>
                    <p>
                      Stay connected with Luxidrive Prime Choose your favourite social
                      media channel
                    </p>
                    <div className="social-icon">
                      <a href="/" target="blank">
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                      <a href="/" target="blank">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="/" target="blank">
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                      <a href="/" target="blank">
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                    <a href="#" className="playstore-button" target="blank">
                      <img
                        src={require("../../assets/images/google-play-store.png")}
                        alt="Get it on Google Play Store"
                      />
                    </a>
                    <a href="#" className="playstore-button" target="blank">
                      <img
                        src={require("../../assets/images/app-store.png")}
                        alt="Get it on Apple Store"
                      />
                    </a>
                  </div>
                </Col>
                {/* <!--IT Solutions ends--> */}
              </Row>

              <div className="bottom-footer">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="copyright-text">
                    <p>
                      © 2022 All Rights Reserved <a href="/">Luxidrive Prime.</a>
                    </p>
                    <p style={{paddingTop:"5px"}}>
                      Dubai | South Africa | Australia | London | New York
                    </p>
                    <p>Bengaluru | Hyderabad | Chennai | Mumbai | Gurgaon | Goa | Cochin </p>
                    {/* <p>
                    Bengaluru | Mysore | Hassan | Davanagere | Tumkuru | Chennai | Hyderabad | Vizag | Tirupati | Madurai | Trichy | Coimbatore | Mumbai | Pune | Goa | Ahmedabad | Baroda | Bhopal | Delhi | Gurgoan | Kolkata | Shimla | Jammu & Kashmir
                    </p> */}
                  </div>
                </div>
              </div>
              {/* bottom footer ends */}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
