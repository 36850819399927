import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate, Collapse } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import { getCarByBrandAndNameAndVersion } from '../../Actions/UserAction';
import { getImageUrl } from '../../Actions/ActionUrl';
import { convertPrice } from '../../Util/Util';
import { FileImageOutlined, BgColorsOutlined, VideoCameraOutlined, DatabaseOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Featurecarssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};


export default class CarVersionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car: false,
            version: false,
        }
        this.getCarData();
    }

    getCarData = () => {
        const brand = this.props.match.params.brand;
        const name = this.props.match.params.name;
        const version = this.props.match.params.version;
        getCarByBrandAndNameAndVersion(brand, name, version).then((car) => {
            if (car) {
                this.setState({ car, version: car.versionData })
            }
        })
    }

    getSpecificationContent = (version) => {
        const panels = [
            {
                heading: 'Engine & Transmission', arr: [
                    { label: 'Engine', value: version.engine },
                    { label: 'Engine Type', value: version.engineType },
                    { label: 'Fuel Type', value: version.fuelType },
                    { label: 'Max Power', value: version.maxPower },
                    { label: 'Max Torque', value: version.maxTorque },
                    { label: 'Mileage', value: version.mileage },
                    { label: 'Drive Train', value: version.driveTrain },
                    { label: 'Transmission', value: version.transmission },
                    { label: 'DrivingRange', value: version.drivingRange },
                    { label: 'Emission Standard', value: version.emissionStandard },
                ]
            },
            {
                heading: 'Dimensions & Weight', arr: [
                    { label: 'Length', value: version.length },
                    { label: 'Width', value: version.width },
                    { label: 'Height', value: version.height },
                    { label: 'Wheel Base', value: version.wheelBase },
                    { label: 'Kerb Weight', value: version.kerbWeight },

                ]
            },
            {
                heading: 'Capacity', arr: [
                    { label: 'Doors', value: version.doors },
                    { label: 'Seating Capacity', value: version.seatingCapacity },
                    { label: 'No Of Seating Rows', value: version.noOfSeatingRows },
                    { label: 'Boot Space', value: version.bootSpace },
                    { label: 'Fuel Tank Capacity', value: version.fuelTankCapacity },

                ]
            }
        ]
        return <Collapse expandIconPosition="right" defaultActiveKey={[]}>
            {panels.map((pa) => {
                return <Panel header={pa.heading} key={pa.heading}>
                    {pa.arr.map(sp => {
                        return <div className="specification-list">
                            <div className="specification-label">{sp.label}</div>
                            <div className="specification-value">{sp.value}</div>
                        </div>
                    })}
                </Panel>
            })}
        </Collapse>
    }

    onViewVersion = (car) => {
        sessionStorage.setItem('version_scroll', true)
        this.props.history.push(`/newcars/${car.brand}/${car.carKey}`)
    }

    render() {
        const { car, version } = this.state;
        const carName = car ? `${car.brandName} ${car.name} ${version.version}` : ''
        return (
            <div>
                {car &&
                    <div className="car-detail">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div style={{ paddingBottom: 15 }}>
                                    <div className="boldtext">{carName}</div>
                                    <Rate value={3} style={{ color: '#ff5421' }} /> <span style={{ paddingLeft: 10 }}>Write Review</span>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Row className="container">
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <Slider {...Featurecarssettings}>
                                            <div className="image-holder">
                                                <img style={{ width: '100%' }} src={getImageUrl(car.defaultImage)} alt="Car Title" />
                                            </div>
                                        </Slider>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <div style={{ padding: 15 }}>
                                            <div className="boldtext">₹ {convertPrice(version.price)}</div>
                                            <div>Avg. Ex-Showroom price</div>
                                        </div>
                                        <table className="navigation-list">
                                            <tr>
                                                <td onClick={() => this.props.history.push(`/newcars/${car.brand}/${car.carKey}/images/all`)}>
                                                    <FileImageOutlined style={{ fontSize: 22 }} />
                                                    <div>Images</div>
                                                </td>
                                                <td onClick={() => this.props.history.push(`/newcars/${car.brand}/${car.carKey}/colors`)}>
                                                    <BgColorsOutlined style={{ fontSize: 22 }} />
                                                    <div>Colors</div>
                                                </td>
                                                {/* <td>
                                                    <VideoCameraOutlined style={{ fontSize: 22 }} />
                                                    <div>Videos</div>
                                                </td> */}
                                                <td>
                                                    <DatabaseOutlined style={{ fontSize: 22 }} onClick={() => this.onViewVersion(car)} />
                                                    <div>Versions</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
                                <Row >
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                        <div className="boldtext" style={{ padding: '10px 0' }}>Specifications & Features</div>
                                    </Col>

                                </Row>
                                {this.getSpecificationContent(version)}
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                        <div className="boldtext" style={{ padding: '10px 0' }}>Summary</div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="container-gray">
                                        {version.summary}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>}
            </div>
        );
    }
}