import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import { getActiveCars } from '../../Actions/UserAction';
import { getImageUrl } from '../../Actions/ActionUrl';
import { convertPrice } from '../../Util/Util';
import { FileImageOutlined, BgColorsOutlined, VideoCameraOutlined, DatabaseOutlined } from '@ant-design/icons';

const Featurecarssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};


export default class CarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cars: []
        }
        this.getCarData();
    }

    getCarData = () => {
        const brand = this.props.match.params.brand;
        getActiveCars(`brand=${brand}`).then((cars) => {
            if (cars) {
                this.setState({ cars });
            }
        })
    }

    render() {
        const { cars } = this.state;
        const brandName = this.props.match.params.brand ? `${this.props.match.params.brand}` : ''
        return (
            <div>
                <div className="car-detail">
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <div style={{ paddingBottom: 15 }}>
                                <div className="boldtext">{brandName} Cars</div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            {cars.map((car) => {
                                const carName = car ? `${car.brandName} ${car.name}` : ''
                                return <Row className="container" onClick={() => this.props.history.push(`/newcars/${car.brand}/${car.carKey}`)} style={{ cursor: 'pointer'}}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
                                        <div className="image-holder">
                                            <img style={{ width: '100%' }} src={getImageUrl(car.defaultImage)} alt="Car Title" />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <div style={{ padding: 15 }}>
                                            <div style={{ fontWeight: 600, fontSize: 22 }}>{carName}</div>
                                            <div>₹ {convertPrice(car.lowestPrice)} - ₹ {convertPrice(car.highestPrice)}</div>
                                            <div>Avg. Ex-Showroom price</div>
                                        </div>

                                    </Col>
                                </Row>
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}