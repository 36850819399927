import React from 'react';
import { Layout, Menu } from 'antd';
import AuthenticatedHeader from './Header/AuthenticatedHeader';
import FooterNav from './Footer/Footer';

const { Header, Footer, Sider, Content } = Layout;

export default class AuthenticatedLayout extends React.Component {
    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <AuthenticatedHeader {...this.props} />
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{ minHeight: '70vh', padding: 10 }}
                    >
                        {this.props.children}
                    </Content>
                    <Footer className="">
                        <FooterNav />
                    </Footer>
                </Layout>
            </Layout>
        );
    }
}