import React from 'react';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import { getLocalStorage, setLocalStorage } from '../../Util/Util';
import { Layout, Table, Tabs, Button, Menu, Dropdown, Space, Row, Col } from 'antd';
import './Cities.scss';
import { getActiveCities, getToken } from '../../Actions/UserAction';
import CitiesFields from './CitiesFields';

export default class Cities extends React.Component {
    constructor() {
        super();
        this.state = {
            cities: [],
        }
        this.getCities();
    }

    getCities = () => {
        getActiveCities().then((cities) => {
            if (cities) {
                this.setState({ cities })
            }
        })
    }

    onSelectCity = (city) => {
        getToken({ city }).then((data) => {
            if (data) {
                getLocalStorage('UNAUTHENTICATED', data.token);
                getLocalStorage('selectedCity', data.city);
                window.location.reload();
            }
        })
    }

    render() {
        const selectedCity = getLocalStorage('selectedCity')
        return (
            <div>
                <div className="all-cities">
                    <Row justify="center">
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="cities-list">
                                <CitiesFields/>
                                {/* <ul>
                                    {this.state.cities.map((city, ind) => {
                                        return <li style={{ background: selectedCity === city.code ? '#f3f3f3' : "#ffffff" }}>
                                            <Link onClick={() => this.onSelectCity(city.code)}>
                                                <div className="brand-details" >
                                                    <div className="brand-name">{city.name}</div>
                                                </div>
                                            </Link>
                                        </li>
                                    })}
                                </ul> */}
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }
}