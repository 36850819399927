import React, { Component } from 'react';
import { Select } from 'antd';
const { Option, OptGroup } = Select;

class SelectBox extends Component {
    constructor(props) {
        super(props);
        this.error = props.optional ? false : `${props.label} required`;
    }
    componentDidMount() {
        this.props.refs && this.props.refs(this);
        if (this.props.value && this.props.value !== '') {
            this.error = false;
        }
    }
    componentWillUnmount() {
        this.props.refs && this.props.refs(null);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit === false && this.props.isSubmit === true) {
            this.error = nextProps.optional ? false : `${nextProps.label} required`;
        }
        if (nextProps.value && nextProps.value !== '' && this.error) {
            this.error = false;
        }
    }

    onChange = (value) => {
        const { id, label, optional, onChangeText } = this.props;
        if (value && value !== '') {
            onChangeText(value, id);
            if (this.error) {
                this.error = false;
            }
        } else {
            if (!optional) {
                this.error = `${label} required`;
            }
            onChangeText(value, id);
        }
    }

    render() {
        const { label, options, nestedOptions, value } = this.props;
        const style = { width: '100%' };
        Object.assign(style, this.props.style || {})
        return (
            <div>
                {label && <label>{label}</label>}
                <Select
                    showSearch
                    placeholder={this.props.placeholder || ''}
                    onChange={this.onChange}
                    style={style}
                    value={value && value !== '' ? `${value}` : undefined}
                    defaultValue={value && value !== '' ? `${value}` : undefined}
                    disabled={this.props.disabled}
                    filterOption={(input, option) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode={this.props.mode || null}
                >
                    {this.props.withNone && <Option key='' value=''>None</Option>}
                    {options && options.map(data => (
                        <Option key={data.label}
                            value={data.value}>
                            {data.label}
                        </Option>
                    ))}
                    {nestedOptions && nestedOptions.map((parent) =>
                        <OptGroup label={parent.label}>
                            {parent.value.map((child) =>
                                <Option key={child.label}
                                    value={child.value}>
                                    {child.label}
                                </Option>
                            )}
                        </OptGroup>
                    )}
                </Select>
                {this.error && this.props.isSubmit && <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>{this.error}</div>}
            </div>
        );
    }
};

export default SelectBox;
