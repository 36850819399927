import React, { Component } from 'react';
import { Row, Col } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import ImageBox from '../../../Components/ImageBox/ImageBox';
import { getActiveBrands, addCar, updateCarWithPicture } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import { getImageUrl } from '../../../Actions/ActionUrl';
import { convertToKey } from '../../../Util/Util';

class CarForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: props.carData ? (props.carData.brand || '') : '',
            name: props.carData ? (props.carData.name || '') : '',
            defaultImage: props.carData ? (props.carData.defaultImage ? getImageUrl(props.carData.defaultImage) : '') : '',
            summary: props.carData ? (props.carData.summary || '') : '',
            priceSummary: props.carData ? (props.carData.priceSummary || '') : '',

            buttonLoader: false,

            brandOptions: []
        }
    }

    componentDidMount() {
        getActiveBrands().then((brands) => {
            const brandOptions = brands.map(val => { return { label: val.name, value: val.code } });
            this.setState({ brandOptions })
        })
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onCar = () => {
        const { brand, name, defaultImage, summary, priceSummary } = this.state;
        const validateFields = ['brand', 'name', 'defaultImage', 'summary', 'priceSummary'];
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
        })
        if (flag) {
            const carData = new FormData();
            carData.append('brand', brand)
            carData.append('name', name)
            carData.append('image', defaultImage)
            carData.append('summary', summary)
            carData.append('priceSummary', priceSummary)
            if (this.props.carId) {
                updateCarWithPicture(this.props.carId, carData).then((car) => {
                    if (car) {
                        this.props.onChangeIndex(1, car)
                    } else {
                        this.setState({ isSubmit: false })
                    }
                })
            } else {
                carData.append('carKey', convertToKey(name))
                addCar(carData).then((car) => {
                    if (car) {
                        this.props.onChangeIndex(1, car)
                    } else {
                        this.setState({ isSubmit: false })
                    }
                })
            }


        } else {
            this.setState({ isSubmit: true })
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <SelectBox label={'Brand'} refs={(ref) => this.brand = ref} id="brand" value={this.state.brand} options={this.state.brandOptions} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <InputBox label={'Name'} refs={(ref) => this.name = ref} id="name" value={this.state.name} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <InputBox label={'Summary'} refs={(ref) => this.summary = ref} id="summary" value={this.state.summary} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <InputBox label={'Price Summary'} refs={(ref) => this.priceSummary = ref} id="priceSummary" value={this.state.priceSummary} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ImageBox label={'Image'} refs={(ref) => this.defaultImage = ref} id={'defaultImage'} value={this.state.defaultImage} isSubmit={this.state.isSubmit} onChange={this.onChangeText} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ButtonBox type="primary" onClick={() => this.onCar()}>
                            Save & Next
                </ButtonBox>
                    </div>
                </Col>
            </Row>
        );
    }
};

export default CarForm; 
