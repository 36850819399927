import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="luxidrive-banner">
          <div className="quikjets-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item slider-1">
                  <Row >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 20 }}
                      md={{ span: 20 }}
                      lg={{ span: 24 }}
                    >
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption s1c">
                            {/* <h2>Rare Earth Metals</h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider-2">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h2>
                              Gold
                            </h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider-3">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            {/* <h2>
                              Coal Energy
                            </h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider-4">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            {/* <h2>
                            Metallurgical Coal
                            </h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-5">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h2>Petroleum</h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider-6">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h2>Petroleum</h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-7">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h2>Petroleum</h2> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider-8">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-9">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-10">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-11">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-12">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-13">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-14">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-15">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item slider-16">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
