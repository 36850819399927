import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Slider from "react-slick";
import "./Home.scss";
// import MainSlider from '../MainSlider/MainSlider';
import HomeSlider from "../LandingPage/HomeSlider/HomeSlider";
import SearchCars from "./SearchCars/SearchCars";
import ChooseStyle from "./ChooseStyle/ChooseStyle";
import FeaturedCars from "../LandingPage/FeaturedCars/FeaturedCars";
// import AboutUs from "../AboutUs/AboutUs";

export default class Home extends React.Component {
  //   constructor() {
  //     super();
  //   }

  render() {
    return (
      <React.Fragment>
        <HomeSlider />
        {/* <AboutUs /> */}
        <SearchCars />
        <ChooseStyle />

        <FeaturedCars />
      </React.Fragment>
    );
  }
}
