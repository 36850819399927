import React, { Component } from "react";
import { Tabs, Space, Card, Row, Col } from "antd";
import { carData } from "../../../Util/carData";

const { Meta } = Card;
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
export default class FeaturedCars extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="inventory bg-gray">
          <h2>Featured Cars</h2>
          <Tabs
            defaultActiveKey="1"
            onChange={callback}
            centered
            style={{ margin: "0px 100px" }}
          >
            <TabPane
              tab={
                <span style={{ fontSize: "30px", margin: "40px" }}>
                  Upcoming
                </span>
              }
              key="1"
            >
              <Row gutter={[12, 24]}>
                {carData.upcomingCarData.map((item, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="car-grid">
                      <img
                        src={item.carImg}
                        alt={`${item.title}`}
                        title={`${item.title}`}
                      />
                      <div className="details">
                        <h3>{item.title}</h3>

                        <ul>
                          <li>
                            <span>Price: </span>
                            {item.price}
                          </li>
                          <li>
                            <span>Year: </span>
                            {item.year}
                          </li>
                          <li>
                            <span>Color: </span>
                            {item.color}
                          </li>
                          <li>
                            <span>KMS: </span>
                            {item.kms}
                          </li>
                          <li>
                            <span>Owner: </span>
                            {item.owner}
                          </li>
                          <li>
                            <span>Registration: </span>
                            {item.registration}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span style={{ fontSize: "25px", margin: "40px" }}>Live</span>
              }
              key="2"
            >
              <Row gutter={[12, 24]}>
                {carData.liveCarData.map((item, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="car-grid">
                      <img
                        src={item.carImg}
                        alt={`${item.title}`}
                        title={`${item.title}`}
                      />
                      <div className="details">
                        <h3>{item.title}</h3>

                        <ul>
                          <li>
                            <span>Price: </span>
                            {item.price}
                          </li>
                          <li>
                            <span>Year: </span>
                            {item.year}
                          </li>
                          <li>
                            <span>Color: </span>
                            {item.color}
                          </li>
                          <li>
                            <span>KMS: </span>
                            {item.kms}
                          </li>
                          <li>
                            <span>Owner: </span>
                            {item.owner}
                          </li>
                          <li>
                            <span>Registration: </span>
                            {item.registration}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span style={{ fontSize: "25px", margin: "40px" }}>Sold</span>
              }
              key="3"
            >
              <Row gutter={[12, 24]}>
                {carData.soldCarData.map((item, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="car-grid">
                      <img
                        src={item.carImg}
                        alt={`${item.title}`}
                        title={`${item.title}`}
                      />
                      <div className="details">
                        <h3>{item.title}</h3>

                        <ul>
                          <li>
                            <span>Year: </span>
                            {item.year}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span style={{ fontSize: "25px", margin: "40px" }}>
                  Popular
                </span>
              }
              key="4"
            >
              <Row gutter={[12, 24]}>
                {carData.popularCarData.map((item, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="car-grid">
                      <img
                        src={item.carImg}
                        alt={`${item.title}`}
                        title={`${item.title}`}
                      />
                      <div className="details">
                        <h3>{item.title}</h3>

                        <ul>
                          <li>
                            <span>Year: </span>
                            {item.year}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span style={{ fontSize: "25px", margin: "40px" }}>
                  Prebook
                </span>
              }
              key="5"
            >
              <Row gutter={[12, 24]}>
                {carData.prebookCarData.map((item, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="car-grid">
                      <img
                        src={item.carImg}
                        alt={`${item.title}`}
                        title={`${item.title}`}
                      />
                      <div className="details">
                        <h3>{item.title}</h3>

                        <ul>
                          <li>
                            <span>Price: </span>
                            {item.price}
                          </li>
                          <li>
                            <span>Year: </span>
                            {item.year}
                          </li>
                          <li>
                            <span>Color: </span>
                            {item.color}
                          </li>
                          <li>
                            <span>KMS: </span>
                            {item.kms}
                          </li>
                          <li>
                            <span>Owner: </span>
                            {item.owner}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
