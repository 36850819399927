import React from 'react';
import { Menu } from 'antd';
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    SettingOutlined,
    DashboardOutlined
} from '@ant-design/icons';
import logoLight from '../assets/images/bms-rebrand-logo.svg';
import logoWithoutText from '../assets/images/bms-rebrand-logo.svg'
const { SubMenu } = Menu;

const menus = [
    { name: 'Dashboard', icon: 'flaticon-home-page', route: 'dashboard' },
    { name: 'Income', icon: 'flaticon-incomes', route: 'income' },
    { name: 'Expenses', icon: 'flaticon-budget' },
    {
        name: 'Master Data', icon: 'flaticon-big-data', subMenus: [
            { name: 'Dashboard', icon: 'flaticon-home-page' },
        ]
    },
    { name: 'Payslip', icon: 'flaticon-payslip' },
]

export default class Sidemenu extends React.Component {

    render() {
        const {
            match: { url },
        } = this.props;
        return (
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['menu0']}>
                <Menu.Item key="logo"
                    style={{
                        margin: '0',
                        padding: 10,
                        minHeight: 65,
                    }} className="brand-logo">
                    <img src={this.props.collapsed ? logoWithoutText : logoLight} alt="SUV Selct" style={{ height: this.props.collapsed ? 20 : 40 }} />
                </Menu.Item>
                {menus.map((menu, menuInd) => {
                    if (menu.subMenus) {
                        return <SubMenu key={`menu${menuInd}`} icon={<i class={menu.icon} style={{ paddingRight: 10 }}></i>} title={this.props.collapsed ? '' : menu.name}>
                            {menu.subMenus.map((subMenu, subMenuInd) => {
                                return <Menu.Item onClick={() => subMenu.route ? this.props.history.push(`${url}/${subMenu.route}`) : {}} key={`submenu${subMenuInd}`} icon={<i class={subMenu.icon} style={{ paddingRight: 10 }}></i>} >{subMenu.name}</Menu.Item>
                            })}
                        </SubMenu>
                    } else {
                        return <Menu.Item onClick={() => menu.route ? this.props.history.push(`${url}/${menu.route}`) : {}} key={`menu${menuInd}`} icon={<i class={menu.icon} style={{ paddingRight: 10 }}></i>}>{this.props.collapsed ? '' : menu.name}</Menu.Item>
                    }
                })}
            </Menu>
        );
    }
}