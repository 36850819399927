import React from "react";
import "./ChooseStyle.scss";
import sedan from "../../../assets/images/cars-styles/sedan.png";
import conertible from "../../../assets/images/cars-styles/convertible.png";
import coupe from "../../../assets/images/cars-styles/coupe.png";
import hatchback from "../../../assets/images/cars-styles/hatchback.png";
import hybrid from "../../../assets/images/cars-styles/hybrid.png";
import muv from "../../../assets/images/cars-styles/muv.png";
import sports from "../../../assets/images/cars-styles/sports.png";
import suv from "../../../assets/images/cars-styles/suv.png";
import { Tabs, Space, Card } from "antd";

const { Meta } = Card;

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

// import Slider from "react-slick";

// import { Carousel } from "antd";

// const contentStyle = {
//   width:200,
// arrows: false,
// dots: false,
// infinite: true,
// speed: 500,
// slidesToShow: 5,
// slidesToScroll: 5,
// autoplay: false,
// autoplaySpeed: 5000,
// };

const ChooseStyle = () => {
  return (
    <div>
      <div className="cmSectors">
        <h1>Choose Your Style</h1>
        <div className="sector">
          <div className="sector-box suv">
            <img src={suv} alt="SUV" />
            <h2>SUV</h2>
          </div>
          <div className="sector-box">
            <img src={sedan} alt="sedan" style={{ paddingTop: "15px" }} />
            <h2>Sedan</h2>
          </div>
          <div className="sector-box">
            <img
              src={conertible}
              alt="convertible"
              style={{ paddingTop: "15px" }}
            />
            <h2>Convertible</h2>
          </div>
          <div className="sector-box">
            <img src={coupe} alt="Coupe" style={{ paddingTop: "15px" }} />
            <h2>Coupe</h2>
          </div>
          <div className="sector-box">
            <img src={sports} alt="sports" style={{ paddingTop: "15px" }} />
            <h2>Sports</h2>
          </div>
          <div className="sector-box">
            <img src={muv} alt="muv" />
            <h2>MUV</h2>
          </div>
          <div className="sector-box">
            <img src={hatchback} alt="Hatchback" />
            <h2>Hatchback</h2>
          </div>
          <div className="sector-box">
            <img src={hybrid} alt="Hybrid" style={{ paddingTop: "15px" }} />
            <h2>Hybrid</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseStyle;
