import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { getImageUrl } from '../../Actions/ActionUrl'

class MultipleImageBox extends Component {
    constructor(props) {
        super(props);
        this.error = props.optional ? false : `${props.label} required`;
        this.state = {
            refresh: false
        }
    }
    componentDidMount() {
        this.props.refs && this.props.refs(this);
        if (this.props.value && this.props.value.length > 0) {
            this.error = false;
        }
    }

    componentWillUnmount() {
        this.props.refs && this.props.refs(null);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit === false && this.props.isSubmit === true) {
            this.error = nextProps.optional ? false : `${nextProps.label} required`;
        }
    }

    onUpload = (file) => {
        const value = [...this.props.value, ...file];
        const { id, label, optional, onChange } = this.props;
        if (value && value.length > 0) {
            onChange(value, id);
            // if (value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/svg+xml') {
            //     if (value.size < this.props.size || value.size < 500000) {
            //         onChange(value, id);
            //         if (this.error) {
            //             this.error = false;
            //             this.forceErr = false;
            //         }
            //     } else {
            //         this.error = `${this.props.label} should be less the 500kb`;
            //         this.forceErr = true;
            //         onChange('', id);
            //     }
            // } else {
            //     this.error = `Unsupported Format`;
            //     this.forceErr = true;
            //     onChange('', id);
            // }
            this.setState({ refresh: true })
        } else {
            if (!optional) {
                this.error = `${label} required`;
            }
            onChange(value, id);
        }
    }

    render() {
        const showErr = this.error && this.props.isSubmit ? this.error : '';
        return (
            <div>
                {this.props.label && <label style={{ textAlign: 'left' }}>{this.props.label}</label>}
                <Dropzone onDrop={this.onUpload} multiple maxSize={8000000} style={{ width: '100%' }}>
                    <div style={{ padding: 5, border: '2px dashed #e4e7ea', borderRadius: 5, cursor: 'pointer', textAlign: 'center', minHeight: this.props.minHeight || 100 }}>
                        {this.props.value && this.props.value.length > 0 ?
                            this.props.value.map((val) => {
                                return <div style={{ margin: 10, background: '#f3f3f3', display: 'inline-table' }}> <div><img alt='Logo' style={{ width: 150, height: 100, padding: 5 }} src={val.preview ? val.preview : getImageUrl(val.path)} /></div></div>
                            })
                            :
                            <div>
                                <div>Upload Picture</div>
                                <div>Drag and drop or click here to upload images</div>
                            </div>}
                    </div>
                </Dropzone>
                <div style={{ fontSize: 12, color: '#f44336', textAlign: 'right' }}>{this.forceErr ? this.error : showErr}</div>
            </div>
        );
    }
};

export default MultipleImageBox;
