import React, { Component } from 'react';
import { Row, Col, Space, Tag, message } from 'antd';
import { ChromePicker } from 'react-color'
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import TableBox from '../../../Components/TableBox/TableBox';
import ModalBox from '../../../Components/ModalBox/ModalBox';
import ModalBoxFooter from '../../../Components/ModalBox/ModalBoxFooter';
import { uploadCarColors } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import ImageBox from '../../../Components/ImageBox/ImageBox';
import { getImageUrl } from '../../../Actions/ActionUrl'

class ManageColors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoader: false,
            colors: props.carData ? (props.carData.colors || []) : [],

            name: '',
            image: '',
            selectedColor: '#fff',
            selectedColors: []
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onSaveColor = () => {
        const { name, image, selectedColors } = this.state;
        const validateFields = ['name', 'image'];
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
        })
        const colorData = new FormData();

        colorData.append('name', name)
        colorData.append('image', image)
        colorData.append('colors', JSON.stringify(selectedColors))
        if (this.state.id) {
            colorData.append('updateId', this.state.id)
        } else {
            colorData.append('id', `CO-${(new Date().valueOf() + parseInt(this.props.carId)).toString(36).toUpperCase()}`)
        }
        if (flag) {
            if (selectedColors.length === 0) {
                message.error('Please add color')
                return true
            }
            uploadCarColors(this.props.carId, colorData).then((car) => {
                if (car) {
                    this.setState({ colors: car.colors || [], open: false })
                }
            })
        } else {
            this.setState({ isSubmit: true })
        }
    }

    onEdit = (rec) => {
        this.setState({ open: true, id: rec.id, name: rec.name, image: getImageUrl(rec.image), selectedColors: rec.colors })
    }

    onCancel = () => {
        this.setState({ open: false, name: '', image: '', selectedColors: [] })
    }

    onFinish = () => {
        // this.props.onChangeIndex(4)
        this.props.history.push('/app/cars')
    }

    render() {
        const columns = [
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                render: image => image ? <img src={getImageUrl(image)} style={{ height: 50, width: 50 }} /> : '',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Color',
                dataIndex: 'colors',
                key: 'colors',
                render: (text, record) => (
                    text && text.length > 0 && <div>{text.map((co) => <div style={{ background: co, width: `${Math.round(40 / text.length)}px`, height: 40, color: '#fff', display: 'inline-table' }}></div>)}</div>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a onClick={() => this.onEdit(record)}>Edit</a>
                    </Space>
                ),
            },
        ];
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <ButtonBox type="primary" onClick={() => this.setState({ open: true })}>
                        Add Color
                </ButtonBox>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <TableBox columns={columns} dataSource={this.state.colors} />
                    <ModalBox title="Brand" visible={this.state.open ? true : false} onOk={this.onSaveColor} onCancel={() => this.onCancel()} destroyOnClose={true}>
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="form-fields">
                                    <InputBox label={'Name'} refs={(ref) => this.name = ref} id="name" value={this.state.name} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="form-fields">
                                    <ImageBox label={'Image'} refs={(ref) => this.image = ref} id={'image'} value={this.state.image} isSubmit={this.state.isSubmit} onChange={this.onChangeText} />
                                </div>
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-fields">
                                    <ChromePicker color={this.state.selectedColor} onChange={(co) => this.setState({ selectedColor: co.hex })} />
                                </div>
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-fields">
                                    <ButtonBox type="default" onClick={() => this.setState({ selectedColors: [...this.state.selectedColors, this.state.selectedColor] })}>
                                        Add Color
                                </ButtonBox>
                                    <div>
                                        {this.state.selectedColors.map((val, ind) => {
                                            return <Tag onClick={() => this.setState({ selectedColors: this.state.selectedColors.filter((v, i) => i !== ind) })} style={{ background: val, marginTop: 10, width: '100%', height: 20 }}></Tag>
                                        })}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBox>
                </Col >
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ButtonBox type="primary" onClick={() => this.onFinish()}>
                            Finish
                        </ButtonBox>
                        {/* <ButtonBox type="secondary" onClick={() => this.props.onChangeIndex(1)}>
                            Previous
                        </ButtonBox> */}
                    </div>
                </Col>
            </Row >
        );
    }
};

export default ManageColors; 
