import React from 'react';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import { Layout, Table, Tabs, Button, Menu, Dropdown, Space, Row, Col } from 'antd';
import './Brands.scss';
import { getImageUrl } from '../../Actions/ActionUrl';
import { getActiveBrands } from '../../Actions/UserAction';

export default class Brands extends React.Component {
    constructor() {
        super();
        this.state = {
            brands: [],
            limit: 12
        }
        this.getBrands();
    }

    getBrands = () => {
        getActiveBrands().then((brands) => {
            if (brands) {
                this.setState({ brands })
            }
        })
    }

    render() {
        return (
            <div>
                <div className="all-brands">
                    <Row justify="center">
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 22 }}>
                            <h2>All SUV Brands</h2>
                            <div className="brands-list">
                                <ul>
                                    {this.state.brands.map((brand, ind) => {
                                        if (ind < this.state.limit) {
                                            return <li>
                                                <Link to={`/newcars/${brand.code}`}>
                                                    <div className="brand-details">
                                                        <img src={getImageUrl(brand.logo.path)} alt="" />
                                                        <div className="brand-name">{brand.name}</div>
                                                    </div>
                                                </Link>
                                            </li>
                                        }
                                    })}
                                </ul>
                                {this.state.brands.length > this.state.limit}<div className="vew-more-brands">
                                    <Link onClick={() => this.setState({ limit: this.state.limit === 12 ? 1000 : 12 })} >{this.state.limit === 12 ? 'View more brands' : 'View less brands'}</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }
}