import React, { Component } from 'react';
import { Table } from 'antd';

class TableBox extends Component {

    render() {
        const { columns, dataSource } = this.props;
        return (
            <div className="custom-background">
                <Table columns={columns} dataSource={dataSource} />
            </div>
        );
    }
};

export default TableBox; 
