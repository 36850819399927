import React from "react";
import "./SeachCars.scss"

class Dropdwn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fleets: [],
      brands: [],
      models: [],
      selectedFleet: "Fleet",
      selectedBrand: "Brand",
    };
    this.changeFleet = this.changeFleet.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
  }

  componentDidMount() {
    //https://reactjs.org/docs/react-component.html#componentdidmount
    this.setState({
      fleets: [{name: "Rolls Royce",brands: [{name: "Rolls Royce Phantom",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                     {name: "Rolls Royce Ghost",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                     {name: "Rolls Royce Wraith",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },],
         },
        {
          name: "BMW" ,brands: [{name:"Bmw 2 Series",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                {name:"BMW 3 Series",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW 5 Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW 6 Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW 7 Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW 8 Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW Z Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW M Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                {name:"BMW i Series", models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               ],
        },
        {
          name:"Mercedes Benz",brands: [{name: "Benz A Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                        {name: "Benz C Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz E Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz S Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz EQC",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz GLA",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz GLC",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz GLE",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz G-Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz Maybach",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz C Class Coupe",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz AMG GT Coupe",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz C-Class Cabriolet",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                        {name: "Benz V Class",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                         ],
        },
           {
          name:"Land Rover",brands: [ {name:"Range Rover",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                     {name:"Range Rover Velar",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover Autobiography",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover First Edition",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover SV",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover Discovery",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover Evoque",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                     {name:"Range Rover Sport",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                    ],
        },
           {
          name:"Audi",brands: [{name:"Audi A3",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                               {name: "Audi A4",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                               {name: "Audi A6",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                               {name: "Audi A8",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi Q2",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi Q3",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi Q5",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi Q7",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi Q8",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi RS Q8",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi S5",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               {name: "Audi RS5",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                               ],
        },
           {
          name:"Bentley" ,brands:[{name:"Bentley Bentayga",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                  {name:"Bentley Mulsanne",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                  {name:"Bentley Flying Spur", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                               ],
        },
           {
          name:"Porsche",brands: [{name:"porsche Cayene",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                  {name:"Porsche Boxster",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                 ],
        },
        {
          name: "Lamborghini" ,brands: [{name:"Lamborghini Huracan",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                       {name:"Lamborghini Aventador",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                      {name:"Lamborghini Gallardo", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                    ],
          },
         {
          name: "Aston Martin" ,brands: [{name:"Martin Vantage",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                       {name:"Martin Rapide",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                      {name:"Martin Vanquish", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                       {name:"Martin DB9", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                    ],
          },
          {
          name: "Jaguar" ,brands: [{name:"Jaguar XF",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                   {name:"Jaguar XE",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Jaguar XJ", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Jaguar F Type", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Jaguar F Pace", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Jaguar XJR", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                  ],
          },
           {
          name: "Ferrari" ,brands: [{name:"Ferrari 458 Speciale",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                   {name:"Ferrari 458 Italia",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Ferrari 488", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Ferrari California", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Ferrari F12 Berlinetta", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Ferrari FF", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                  ],
          },
           {
          name: "Maserati" ,brands: [{name:"Maserati Ghibli",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                   {name:"Maserati Quattroporte",models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Maserati GranCabrio", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Ferrari California", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                   {name:"Maserati GranTurismo", models: ["2012-2014","2015-2017","2018-2020","2021-2022"] },
                                  ],
          },
           {
          name:"Volvo",brands: [{name:"Volvo XC40",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                                {name:"Volvo XC90",models: ["2012-2014","2015-2017","2018-2020","2021-2022"]},
                                 ],
        },
           {
          name:"Jeep",brands: [{name:"Jeep Compass",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                               {name:"Jeep Wrangler",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                               {name:"Jeep Compass Trailhawk",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                               {name:"Jeep Grand Cherokee",models: ["2012-2014","2015-2017","2018-2020","2021-2022"],},
                              ],
        },
      ],

    });
  }

  changeFleet(event) {
    this.setState({ selectedFleet: event.target.value });
    this.setState({
      brands: this.state.fleets.find(
        (flt) => flt.name === event.target.value
      ).brands,
    });
  }

  changeBrand(event) {
    this.setState({ selectedBrand: event.target.value });
    const brnds = this.state.fleets.find(
      (flt) => flt.name === this.state.selectedFleet
    ).brands;
    this.setState({
      models: brnds.find((brnd) => brnd.name === event.target.value).models,
    });
  }

  render() {
    return (
      <div>
        
        <select
          placeholder="Select Fleets"
          value={this.state.selectedFleet}
          onChange={this.changeFleet}
          style={{ width: "220px",}}
    className="select"  >
          <option>---Select Brand---</option>
          {this.state.fleets.map((e, key) => {
            return <option key={key}>{e.name}</option>;
          })}
        </select>

        <select
          placeholder="State"
          value={this.state.selectedBrand}
          onChange={this.changeBrand}
          style={{ width: "220px", marginRight: "16px" }}
          className="select" >
          <option>---Select Models---</option>
          {this.state.brands.map((e, key) => {
            return <option key={key}>{e.name}</option>;
          })}
        </select>

        <select
          placeholder="City"
          style={{ width: "220px", marginRight: "16px" }}
          className="select"  >
          <option>---Select Year---</option>
          {this.state.models.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
      </div>
    );
  }
}

export default Dropdwn;
