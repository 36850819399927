import React, { Component } from 'react';
import { Row, Col, message } from 'antd';
import InputBox from '../../../../Components/InputBox/InputBox';
import SelectBox from '../../../../Components/SelectBox/SelectBox';
import ButtonBox from '../../../../Components/ButtonBox/ButtonBox';
import ModalBoxFooter from '../../../../Components/ModalBox/ModalBoxFooter';
import { addCity, updateCity } from '../../../../Actions/UserAction';
import { convertToKey } from '../../../../Util/Util';
import { DeleteFilled } from '@ant-design/icons';

const STATUS_OPTIONS = [
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'InActive',
        label: 'InActive',
    },
]

class CityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.cityData ? (props.cityData.name || '') : '',
            priceRange: props.cityData ? (props.cityData.priceRange || [{ from: '', to: '', percentange: '' }]) : [{ from: '', to: '', percentange: '' }],
            status: props.cityData ? (props.cityData.status || '') : '',
            buttonLoader: false
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onChangeTextItems = (value, type, ind) => {
        const changeObj = Object.assign({}, this.state.priceRange[ind], { [type]: value })
        const priceRange = this.state.priceRange.map((val, i) => i === ind ? changeObj : val)
        this.setState({ priceRange });
    }


    onCity = () => {
        const { name, priceRange, status } = this.state;
        const validateFields = ['name', 'status'];
        priceRange.map((val, ind) => {
            validateFields.push(`from${ind}`, `to${ind}`, `percentage${ind}`);
            return true
        })
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
        })
        if (flag) {
            const code = convertToKey(name)
            this.setState({ buttonLoader: true }, () => {
                if (this.props.cityData) {
                    updateCity(this.props.cityData.id, { name, code, priceRange, status }).then((city) => {
                        if (city) {
                            this.props.onCancel(city, 'update');
                        }
                        this.setState({ buttonLoader: false })
                    })
                } else {
                    addCity({ name, code, priceRange, status }).then((city) => {
                        if (city) {
                            this.props.onCancel(city, 'add');
                        }
                        this.setState({ buttonLoader: false })
                    })
                }
            })
        } else {
            this.setState({ isSubmit: true })
        }
    }

    onAddPriceRange = () => {
        this.setState({ priceRange: [...this.state.priceRange, { from: '', to: '', percentange: '' }] })
    }

    onDeletePriceRange = (ind) => {
        if (this.state.priceRange.length > 1) {
            this.setState({ priceRange: this.state.priceRange.filter((val, i) => i !== ind) })
        } else {
            message.error('Atleast one range required')
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <InputBox label={'Name'} refs={(ref) => this.name = ref} id="name" value={this.state.name} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <Row style={{ marginTop: 10 }}>
                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div>Price Range</div>
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <ButtonBox type="secondary" style={{ float: 'right' }} onClick={this.onAddPriceRange}>Add</ButtonBox>
                            </Col>
                        </Row>
                    </div>
                    {this.state.priceRange.map((item, ind) => {
                        return <Row>
                            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <div className="form-fields">
                                    <InputBox label={'From'} refs={(ref) => this[`from${ind}`] = ref} id="from" value={item.from} onChangeText={(value, type) => this.onChangeTextItems(value, type, ind)} isSubmit={this.state.isSubmit} />
                                </div>
                            </Col>
                            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <div className="form-fields">
                                    <InputBox label={'To'} refs={(ref) => this[`to${ind}`] = ref} id="to" value={item.to} onChangeText={(value, type) => this.onChangeTextItems(value, type, ind)} isSubmit={this.state.isSubmit} />
                                </div>
                            </Col>
                            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <div className="form-fields">
                                    <InputBox label={'Percentange'} refs={(ref) => this[`percentange${ind}`] = ref} id="percentange" value={item.percentange} onChangeText={(value, type) => this.onChangeTextItems(value, type, ind)} isSubmit={this.state.isSubmit} />
                                </div>
                                <DeleteFilled style={{ fontSize: 18, color: 'red', position: 'absolute', top: 36, right: 0, cursor: 'pointer' }} onClick={() => this.onDeletePriceRange(ind)} />
                            </Col>
                        </Row>
                    })}
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <SelectBox label={'Status'} refs={(ref) => this.status = ref} id="status" value={this.state.status} options={STATUS_OPTIONS} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <ModalBoxFooter loader={this.state.buttonLoader} onOk={this.onCity} onCancel={() => this.props.onCancel()} />
            </Row>
        );
    }
};

export default CityForm; 
