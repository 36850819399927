import React, { Component } from 'react';
import { Row, Col, Space, Tag, message } from 'antd';
import { ChromePicker } from 'react-color'
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import TableBox from '../../../Components/TableBox/TableBox';
import ModalBox from '../../../Components/ModalBox/ModalBox';
import VersionForm from './VersionForm';
import { getCarVersions, updateCar } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import ImageBox from '../../../Components/ImageBox/ImageBox';
import { getImageUrl } from '../../../Actions/ActionUrl'

class ManageVersions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoader: false,
            versions: [],

            open: false,
            versionData: false
        }
        this.getVersions();
    }

    sortVersion = (arr) => {
        return arr.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))
    }

    getVersions = () => {
        getCarVersions(this.props.carId).then((versions) => {
            if (versions) {
                this.setState({ versions: this.sortVersion(versions) })
            }
        })
    }

    onEdit = (versionData) => {
        this.setState({ open: true, versionData })
    }

    onCancel = (version, type) => {
        if (version) {
            const versions = type === 'add' ? [version, ...this.state.versions] : this.state.versions.map(val => val.id === version.id ? version : val);
            this.setState({ open: false, versionData: false, versions: this.sortVersion(versions) })
        } else {
            this.setState({ open: false, versionData: false })
        }
    }

    onFinish = () => {
        const { versions } = this.state;
        if (versions.length > 0) {
            const lowest = versions[0];
            const highest = versions[versions.length - 1];
            const defualtVersion = lowest.id
            updateCar(this.props.carId, { lowestPrice: lowest.price, highestPrice: highest.price, defualtVersion }).then((car) => {
                if (car) {
                    this.props.onChangeIndex(2);
                }
            })
        } else {
            message.error('Please add atleast one version')
        }

    }

    render() {
        const columns = [
            {
                title: 'Version Name',
                dataIndex: 'version',
                key: 'version',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a onClick={() => this.onEdit(record)}>Edit</a>
                    </Space>
                ),
            },
        ];
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <ButtonBox type="primary" onClick={() => this.setState({ open: true })}>
                        Add Version
                </ButtonBox>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <TableBox columns={columns} dataSource={this.state.versions} />
                    <ModalBox title="Version" visible={this.state.open} footer={null} onCancel={() => this.onCancel()} destroyOnClose={true} width="70%">
                        <VersionForm versionData={this.state.versionData} carId={this.props.carId} onCancel={this.onCancel} />
                    </ModalBox>
                </Col >
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ButtonBox type="primary" onClick={() => this.onFinish()}>
                            Next
                        </ButtonBox>
                        <ButtonBox type="secondary" onClick={() => this.props.onChangeIndex(0)}>
                            Previous
                        </ButtonBox>
                    </div>
                </Col>
            </Row >
        );
    }
};

export default ManageVersions; 
