import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from 'react-router-dom';
import { getLocalStorage } from '../Util/Util';
import Home from '../Screens/LandingPage/Home';
import AuthenticatedLayout from '../Layout/AuthenticatedLayout';
import AdminDashboard from '../Screens/Admin/AdminDashboard/AdminDashboard';
import Brands from '../Screens/Admin/MasterDataManagement/Brands/Brands';
import Cities from '../Screens/Admin/MasterDataManagement/Cities/Cities';

import Cars from '../Screens/Admin/CarsManagement/Cars';
import ManageCar from '../Screens/Admin/CarsManagement/ManageCar';

class AuthenticatedRoutes extends React.Component {
    render() {
        const {
            match: { url },
        } = this.props;
        if (!getLocalStorage('AUTHENTICATED')) {
            return <Redirect to="/" />
        }
        return (
            <AuthenticatedLayout {...this.props}>
                <Switch>
                    <Route exact path={`${url}/admin-dashboard`} component={AdminDashboard} />
                    <Route exact path={`${url}/brands`} component={Brands} />
                    <Route exact path={`${url}/cities`} component={Cities} />
                    <Route exact path={`${url}/cars`} component={Cars} />
                    <Route exact path={`${url}/add-car`} component={ManageCar} />
                    <Route exact path={`${url}/edit-car/:carId`} component={ManageCar} />
                </Switch>
            </AuthenticatedLayout>
        );
    }
}

export default AuthenticatedRoutes;
