import axios from 'axios';
import { UserType } from './ActionType';
import { message } from 'antd';
import Url from './ActionUrl';

const API_URL = Url.API_URL;

const formDataHeader = {
    'Accept': 'application/x-www-form-urlencoded',
    "Content-Type": "application/x-www-form-urlencoded",
    "cache-control": "no-cache",
};

export const login = (data) => {
    return function (dispatch) {
        return axios.post(`${API_URL}/admins/login`, data)
            .then((res) => {
                if (res.data.success) {
                    dispatch({ type: UserType.GET_USER, payload: res.data.user });
                    return res.data.result
                } else {
                    message.error('Invalid Credentials');
                    return false
                }

            })
            .catch((err) => {
                message.error('Invalid Credentials');
            });
    };
};

// ---------- Auth ---------- //


export const getToken = (data) => {
    return axios.post(`${API_URL}/auth/getToken`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                return false
            }
        })
        .catch((err) => {
        });
};

// ---------- Brands ---------- //

export const addBrand = (data) => {
    return axios.post(`${API_URL}/brands/addBrand`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Adding brand failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Adding brand failed');
        });
};

export const getBrands = () => {
    return axios.get(`${API_URL}/brands/getAll`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting brand failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting brand failed');
        });
};

export const getActiveBrands = (data) => {
    return axios.get(`${API_URL}/brands/getAllActive`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting brand failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting brand failed');
        });
};

export const updateBrand = (id, data) => {
    return axios.put(`${API_URL}/brands/updateById/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Updating brand failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Updating brand failed');
        });
};

// ---------- Cities ---------- //

export const addCity = (data) => {
    return axios.post(`${API_URL}/cities/addCity`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Adding city failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Adding city failed');
        });
};

export const getCities = () => {
    return axios.get(`${API_URL}/cities/getAll`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting city failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting city failed');
        });
};

export const getActiveCities = () => {
    return axios.get(`${API_URL}/cities/getAllActive`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting cities failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cities failed');
        });
};

export const updateCity = (id, data) => {
    return axios.put(`${API_URL}/cities/updateById/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Updating city failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Updating city failed');
        });
};

// ---------- Cars ---------- //

export const addCar = (data) => {
    return axios.post(`${API_URL}/cars/addCar`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Adding car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Adding car failed');
        });
};

export const getCars = () => {
    return axios.get(`${API_URL}/cars/getAll`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting cars failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const getActiveCars = (query) => {
    const url = query ? `${API_URL}/cars/getAllActive?${query}` : `${API_URL}/cars/getAllActive`;
    return axios.get(url)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting cars failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const getCarById = (id) => {
    return axios.get(`${API_URL}/cars/getById/${id}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const getCarByBrandAndName = (brand, name) => {
    return axios.get(`${API_URL}/cars/getByBrandAndName/${brand}/${name}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const getCarByBrandAndNameAndVersion = (brand, name, version) => {
    return axios.get(`${API_URL}/cars/getByBrandAndNameAndVersion/${brand}/${name}/${version}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const updateCar = (id, data) => {
    return axios.put(`${API_URL}/cars/updateById/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Updating car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Updating city failed');
        });
};

export const updateCarWithPicture = (id, data) => {
    return axios.put(`${API_URL}/cars/updateByIdWithPicture/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Updating car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Updating city failed');
        });
};

export const uploadCarImages = (id, data) => {
    return axios.put(`${API_URL}/cars/updloadImages/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Uploading car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Uploading city failed');
        });
};

export const uploadCarColors = (id, data) => {
    return axios.put(`${API_URL}/cars/updloadColors/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Uploading car failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Uploading city failed');
        });
};

// ---------- Car Versions ---------- //

export const addCarVersion = (data) => {
    return axios.post(`${API_URL}/cars/versions/add`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Adding version failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Adding version failed');
        });
};

export const getCarVersions = (id) => {
    return axios.get(`${API_URL}/cars/versions/byCarId/${id}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Getting cars failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Getting cars failed');
        });
};

export const updateCarVersion = (id, data) => {
    return axios.put(`${API_URL}/cars/versions/updateById/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                return res.data.result
            } else {
                message.error('Updating version failed');
                return false
            }

        })
        .catch((err) => {
            message.error('Updating version failed');
        });
};
