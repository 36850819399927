import React, { Component } from 'react';
import { Input } from 'antd';
import { email_validate, number_validate, url_validate } from './InputValidations';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { TextArea } = Input;

class InputBox extends Component {
    constructor(props) {
        super(props);
        this.error = props.optional ? false : `${props.label || props.placeholder} required`;
        this.state = {
            showPassword: false
        }
    }
    componentDidMount() {
        this.props.refs && this.props.refs(this);
        if (this.props.value && this.props.value !== '') {
            this.error = false;
        }
    }
    componentWillUnmount() {
        this.props.refs && this.props.refs(null);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit === false && this.props.isSubmit === true) {
            this.error = nextProps.optional ? false : `${nextProps.label || nextProps.placeholder} required`;
        }
        if (nextProps.value && nextProps.value !== '' && this.error) {
            this.validateInput(nextProps.value, true);
        }
    }

    validateInput = (value, setFalse) => {
        const { inputType, label, placeholder } = this.props;
        switch (inputType) {
            case 'email':
                this.error = email_validate(value) ? `Invalid ${label || placeholder}` : false;
                break;
            case 'number':
                this.error = number_validate(value) ? `Invalid ${label || placeholder}` : false;
                break;
            case 'url':
                this.error = url_validate(value) ? `Invalid ${label || placeholder}` : false;
                break;
            case 'password':
                this.error = (value.length <= 4 && `Weak ${label || placeholder}`) || (value.length >= 5 && value.length <= 7 && `Medium ${label || placeholder}`) || false;
                break;
            case 'confirmPassword':
                this.error = this.props.password && this.props.password !== value ? `${label || placeholder} not matching with password` : false;
                break;
            default:
                this.error = setFalse ? false : this.error;
                break;
        }
    }

    onChange = (e) => {
        const value = e.target.value;
        const { id, label, placeholder, optional, onChangeText } = this.props;
        if (value && value !== '') {
            onChangeText(value, id);
            if (this.error) {
                this.error = false;
            }
            this.validateInput(value);
        } else {
            if (!optional) {
                this.error = `${label || placeholder} required`;
            }
            onChangeText(value, id);
        }
    }

    render() {
        const { label } = this.props;
        return (
            <div>
                {label && <label style={{ textAlign: 'left' }}>{label}</label>}
                {this.props.textArea ?
                    <TextArea rows={4}
                        id={this.props.id}
                        name={this.props.name}
                        placeholder={this.props.placeholder || ''}
                        value={this.props.value}
                        className={this.props.className}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                    />
                    :
                    <Input
                        id={this.props.id}
                        name={this.props.name}
                        placeholder={this.props.placeholder || ''}
                        value={this.props.value}
                        type={this.props.type || 'text'}
                        className={this.props.className}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                        prefix={this.props.prefix ? <i className={this.props.prefix} /> : null}
                        suffix={this.props.type === 'password' ? (this.state.showPassword ? <EyeTwoTone onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> : <EyeInvisibleOutlined onClick={() => this.setState({ showPassword: !this.state.showPassword })} />) : this.props.suffix ? <i className={this.props.suffix} /> : null}
                    />}

                {this.error && this.props.isSubmit && <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>{this.error}</div>}
            </div>
        );
    }
};

export default InputBox;
