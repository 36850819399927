import React, { Component } from 'react';
import { Row, Col, Space } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import TableBox from '../../../Components/TableBox/TableBox';
import ModalBox from '../../../Components/ModalBox/ModalBox';
import ModalBoxFooter from '../../../Components/ModalBox/ModalBoxFooter';
import { uploadCarColors } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import ImageBox from '../../../Components/ImageBox/ImageBox';
import { getImageUrl } from '../../../Actions/ActionUrl'
import { getCars } from '../../../Actions/UserAction'

class Cars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoader: false,

            cars: [],
        }
    }

    componentDidMount() {
        getCars().then((cars) => {
            if (cars) {
                this.setState({ cars })
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'Image',
                dataIndex: 'defaultImage',
                key: 'defaultImage',
                render: image => image ? <img src={getImageUrl(image)} style={{ height: 50, width: 50 }} /> : '',
            },
            {
                title: 'Brand',
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a onClick={() => this.props.history.push(`/app/edit-car/${record.carId}`)}>Edit</a>
                    </Space>
                ),
            },
        ];
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <ButtonBox type="primary" onClick={() => this.props.history.push('/app/add-car')}>
                        Add New car
                    </ButtonBox>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <TableBox columns={columns} dataSource={this.state.cars} />
                </Col>
            </Row>
        );
    }
};

export default Cars; 
