// Popular Car Data
import RangeRoverAutobiography from "../assets/images/cars/popular/range-rover-autobiography.jpg";
import PopRangeRoverVelar from "../assets/images/cars/popular/range-rover-velar.jpg";
import RangeRoverEvoque from "../assets/images/cars/popular/range-rover-evoque.jpg";
import PorscheCayenne from "../assets/images/cars/popular/porsche-cayenne.jpg";
import LexusES300h from "../assets/images/cars/popular/lexus-es-300h.jpg";
// Live Car Data
import PorscheCayene from "../assets/images/cars/live/porsche-cayene.jpg";
import LiveRangeRoverSport from "../assets/images/cars/live/range-rover-sport.jpg";
import RangeRoverDiscovery from "../assets/images/cars/live/range-rover-discovery.jpg";
import BMXX6 from "../assets/images/cars/live/bmx-x6.jpg";
import MercedesGLC300D from "../assets/images/cars/live/mercedes-glc-300d.jpg";
// prebook car data
import PreRangeRoverSport from "../assets/images/cars/pre-book/range-roversport.jpg";
import BMW520DMSport from "../assets/images/cars/pre-book/bmw-520d-m-sport.jpg";
import BMWX5XDrive40iMSport from "../assets/images/cars/pre-book/bmw-x5-x-drive-40i-m-sport.jpg";
import MaseratiLenvateTofeo from "../assets/images/cars/pre-book/maserati-lenvate-tofeo.jpg";
import MiniCountryMan from "../assets/images/cars/pre-book/mini-country-man.jpg";
import BMX7XDrive40iMSport from "../assets/images/cars/pre-book/bmx7-xdrive-40i-m-sport.jpg";

// sold car data
import SolLamborghiniAventador from "../assets/images/cars/sold/lamborghini-aventador.jpg";
import RollsRoyce from "../assets/images/cars/sold/rolls-royce.jpg";
import NissanGT from "../assets/images/cars/sold/nissan-gt.jpg";
import BMWX5 from "../assets/images/cars/sold/bmw-x5.jpg";
import MercedesGLS600D from "../assets/images/cars/sold/mercedes-gls-600d.jpg";

// upcoming car data
import RangeRoverAutiobiography from "../assets/images/cars/upcoming/range-rover-autobiography.jpg";
import MaseratiGhibhli from "../assets/images/cars/upcoming/maserati-ghibhli.jpg";
import UpcomLamborghiniAventador from "../assets/images/cars/upcoming/lamborghini-aventador.jpg";
import BMWZ4 from "../assets/images/cars/upcoming/bmw-z4.jpg";
import UpRangeRoverVelar from "../assets/images/cars/upcoming/range-rover-velar.jpg";
import BMWX3 from "../assets/images/cars/upcoming/bmw-x3.jpg";

export const carData = {
  upcomingCarData: [
    {
      id: "1",
      title: "Range Rover Autobiography",
      carImg: [RangeRoverAutiobiography],
      year: "2020",
      color: "Black",
      kms: "2000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "1.60 Cr",
    },
    {
      id: "2",
      title: "Maserati Ghibhli",
      carImg: [MaseratiGhibhli],
      year: "2019",
      color: "blue",
      kms: "30000",
      owner: "Single Owner",
      registration: "Mumbai",
      price: "80 Lacs",
    },
    {
      id: "3",
      title: "Lamborghini Aventador",
      carImg: [UpcomLamborghiniAventador],
      year: "2019",
      color: "yellow",
      kms: "50000",
      owner: "Single Owner",
      registration: "del",
      price: "2 .5cr",
    },
    {
      id: "4",
      title: "BMW Z4",
      carImg: [BMWZ4],
      year: "2020",
      color: "white",
      kms: "3000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "60 Lacs",
    },
    {
      id: "5",
      title: "Range Rover Velar",
      carImg: [UpRangeRoverVelar],
      year: "2019",
      color: "silver",
      kms: "6000",
      owner: "Single Owner",
      registration: "Bangalore",
      price: "75 Lacs",
    },
    {
      id: "6",
      title: "BMW X3",
      carImg: [BMWX3],
      year: "2020",
      color: "blue",
      kms: "20000",
      owner: "Single Owner",
      registration: "Bangalore",
      price: "80 Lacs",
    },
  ],
  liveCarData: [
    {
      id: "1",
      title: "Porsche Cayene",
      carImg: [PorscheCayene],
      year: "2020",
      color: "Black",
      kms: "3000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "80 Lacs",
    },
    {
      id: "2",
      title: "Range Rover Sport",
      carImg: [LiveRangeRoverSport],
      year: "2019",
      color: "Black",
      kms: "7000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "1.2 Cr",
    },
    {
      id: "3",
      title: "Range Rover Discovery",
      carImg: [RangeRoverDiscovery],
      year: "2019",
      color: "white",
      kms: "25000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "70 Lacs",
    },
    {
      id: "4",
      title: "BMX X6",
      carImg: [BMXX6],
      year: "2019",
      color: "blue",
      kms: "7000",
      owner: "Single Owner",
      registration: "Karnataka",
      price: "90 Lacs",
    },
    {
      id: "5",
      title: "Mercedes GLC 300D",
      carImg: [MercedesGLC300D],
      year: "2019",
      color: "red",
      kms: "5000",
      owner: "Single Owner",
      registration: "PY",
      price: "55 Lacs",
    },
  ],
  soldCarData: [
    {
      id: "1",
      title: "Lamborghini Aventador",
      carImg: [SolLamborghiniAventador],
      year: "2020",
    },
    {
      id: "2",
      title: "Rolls Royce",
      carImg: [RollsRoyce],
      year: "2019",
    },
    {
      id: "3",
      title: "Nissan GT",
      carImg: [NissanGT],
      year: "2018",
    },
    {
      id: "4",
      title: "BMW X5",
      carImg: [BMWX5],
      year: "2020",
    },
    {
      id: "5",
      title: "Mercedes GLS 600D",
      carImg: [MercedesGLS600D],
      year: "2019",
    },
  ],
  popularCarData: [
    {
      id: "1",
      title: "Range Rover Autobiography",
      carImg: [RangeRoverAutobiography],
      year: "2022",
    },

    {
      id: "2",
      title: "Range Rover Velar",
      carImg: [PopRangeRoverVelar],
      year: "2022",
    },
    {
      id: "3",
      title: "Range Rover Evoque",
      carImg: [RangeRoverEvoque],
      year: "2022",
    },
    {
      id: "4",
      title: "Porsche Cayenne",
      carImg: [PorscheCayenne],
      year: "2022",
    },
    {
      id: "5",
      title: "Lexus ES 300h",
      carImg: [LexusES300h],
      year: "2022",
    },
  ],

  prebookCarData: [
    {
      id: "1",
      title: "Range Rover Sport",
      carImg: [PreRangeRoverSport],
      year: "2022",
      color: "Black",
      kms: "2000",
      owner: "Single Owner",
      price: "1.2 cr",
    },
    {
      id: "2",
      title: "BMW 520D M Sport",
      carImg: [BMW520DMSport],
      year: "2021",
      color: "silver",
      kms: "6000",
      owner: "Single Owner",
      price: "55 Lacs",
    },
    {
      id: "3",
      title: "BMW X5 X Drive 40i M Sport",
      carImg: [BMWX5XDrive40iMSport],
      year: "2021",
      color: "blue",
      kms: "8000",
      owner: "Single Owner",
      price: "65 Lacs",
    },
    {
      id: "4",
      title: "Maserati Lenvate Tofeo",
      carImg: [MaseratiLenvateTofeo],
      year: "2021",
      color: "blue",
      kms: "2000",
      owner: "Single Owner",
      price: "72 Lacs",
    },
    {
      id: "5",
      title: "Mini Country Man",
      carImg: [MiniCountryMan],
      year: "2021",
      color: "green",
      kms: "5000",
      owner: "Single Owner",
      price: "27 Lacs",
    },
    {
      id: "6",
      title: "BMX7 XDrive 40i M Sport",
      carImg: [BMX7XDrive40iMSport],
      year: "2021",
      color: "blue",
      kms: "3200 ",
      owner: "Single Owner",
      price: "27 Lacs",
    },
  ],
};
