import React from 'react';
import { Link } from 'react-router-dom';
import CarColors from './CarColors';
import CarVersionDetails from './CarVersionDetails';

export default class CarAllDetails extends React.Component {
    render() {
        const version = this.props.match.params.version;
        if (version === 'colors') {
            return <CarColors {...this.props} />
        } else {
            return <CarVersionDetails {...this.props} />
        }
    }
}