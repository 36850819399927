import React, { Component } from 'react';
import { Row, Col } from 'antd';
import InputBox from '../../../../Components/InputBox/InputBox';
import ImageBox from '../../../../Components/ImageBox/ImageBox';
import SelectBox from '../../../../Components/SelectBox/SelectBox';
import ModalBoxFooter from '../../../../Components/ModalBox/ModalBoxFooter';
import { addBrand, updateBrand } from '../../../../Actions/UserAction'
import { getImageUrl } from '../../../../Actions/ActionUrl'
import { convertToKey } from '../../../../Util/Util';

const STATUS_OPTIONS = [
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'InActive',
        label: 'InActive',
    },
]

class BrandBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.brandData ? (props.brandData.name || '') : '',
            logo: props.brandData ? (props.brandData.logo ? getImageUrl(props.brandData.logo.path) : '') : '',
            status: props.brandData ? (props.brandData.status || '') : '',
            buttonLoader: false
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onBrand = () => {
        const { name, logo, status } = this.state;
        const validateFields = ['name', 'logo', 'status'];
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
        })
        if (flag) {
            const brandData = new FormData();
            brandData.append('name', name);
            brandData.append('image', logo);
            brandData.append('status', status);
            this.setState({ buttonLoader: true }, () => {
                if (this.props.brandData) {
                    brandData.append('oldFileName', this.props.brandData.logo.name);
                    updateBrand(this.props.brandData.id, brandData).then((brand) => {
                        if (brand) {
                            this.props.onCancel(brand, 'update');
                        }
                        this.setState({ buttonLoader: false })
                    })
                } else {
                    brandData.append('code', convertToKey(name));
                    addBrand(brandData).then((brand) => {
                        if (brand) {
                            this.props.onCancel(brand, 'add');
                        }
                        this.setState({ buttonLoader: false })
                    })
                }
            })
        } else {
            this.setState({ isSubmit: true })
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <InputBox label={'Name'} refs={(ref) => this.name = ref} id="name" value={this.state.name} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <ImageBox label={'Logo'} refs={(ref) => this.logo = ref} id={'logo'} value={this.state.logo} isSubmit={this.state.isSubmit} onChange={this.onChangeText} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <SelectBox label={'Status'} refs={(ref) => this.status = ref} id="status" value={this.state.status} options={STATUS_OPTIONS} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <ModalBoxFooter loader={this.state.buttonLoader} onOk={this.onBrand} onCancel={() => this.props.onCancel()} />
            </Row>
        );
    }
};

export default BrandBox; 
