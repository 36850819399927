import React, { useState } from 'react';
import { Row, Col } from 'antd';
import InputBox from '../../Components/InputBox/InputBox';
import ButtonBox from '../../Components/ButtonBox/ButtonBox';
import './Login.scss';

export default class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="user login-register-fields">
                        <form>
                            <div class="form-fields">
                                <InputBox refs={(ref) => this.email = ref} label="Email ID" id="email" value={this.state.email} isSubmit={this.state.isSubmit} prefix={'flaticon-user-2'} onChangeText={this.onChangeText} />
                            </div>
                            <div class="form-fields">
                                <InputBox refs={(ref) => this.Password = ref} label="Password" id="password" value={this.state.password} isSubmit={this.state.isSubmit} prefix={'flaticon-locked'} type={'password'} onChangeText={this.onChangeText} />
                            </div>
                            <br />
                            <div class="form-fields">
                                <ButtonBox type="primary">Login</ButtonBox>
                            </div>
                        </form>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="user login-register-fields">
                        <form>
                            <div class="form-fields">
                                <InputBox refs={(ref) => this.email = ref} label="Email ID" id="email" value={this.state.email} isSubmit={this.state.isSubmit} prefix={'flaticon-user-2'} onChangeText={this.onChangeText} />
                            </div>
                            <div class="form-fields">
                                <InputBox refs={(ref) => this.Password = ref} label="Password" id="password" value={this.state.password} isSubmit={this.state.isSubmit} prefix={'flaticon-locked'} type={'password'} onChangeText={this.onChangeText} />
                            </div>
                            <br />
                            <div class="form-fields">
                                <ButtonBox type="primary">Sign up</ButtonBox>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        );
    }
}