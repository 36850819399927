import React, { Component } from 'react';
import { Row, Col } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import ImageBox from '../../../Components/ImageBox/ImageBox';
import { addCarVersion, updateCarVersion } from '../../../Actions/UserAction';
import ButtonBox from '../../../Components/ButtonBox/ButtonBox';
import { getImageUrl } from '../../../Actions/ActionUrl';
import ModalBoxFooter from '../../../Components/ModalBox/ModalBoxFooter';
import { convertToKey } from '../../../Util/Util';

class VersionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: props.versionData ? (props.versionData.version || '') : '',
            price: props.versionData ? (props.versionData.price || '') : '',
            summary: props.versionData ? (props.versionData.summary || '') : '',
            engine: props.versionData ? (props.versionData.engine || '') : '',
            engineType: props.versionData ? (props.versionData.engineType || '') : '',
            fuelType: props.versionData ? (props.versionData.fuelType || '') : '',
            maxPower: props.versionData ? (props.versionData.maxPower || '') : '',
            maxTorque: props.versionData ? (props.versionData.maxTorque || '') : '',
            mileage: props.versionData ? (props.versionData.mileage || '') : '',
            driveTrain: props.versionData ? (props.versionData.driveTrain || '') : '',
            transmission: props.versionData ? (props.versionData.transmission || '') : '',
            drivingRange: props.versionData ? (props.versionData.drivingRange || '') : '',
            emissionStandard: props.versionData ? (props.versionData.emissionStandard || '') : '',

            length: props.versionData ? (props.versionData.length || '') : '',
            width: props.versionData ? (props.versionData.width || '') : '',
            height: props.versionData ? (props.versionData.height || '') : '',
            wheelBase: props.versionData ? (props.versionData.wheelBase || '') : '',
            kerbWeight: props.versionData ? (props.versionData.kerbWeight || '') : '',

            doors: props.versionData ? (props.versionData.doors || '') : '',
            seatingCapacity: props.versionData ? (props.versionData.seatingCapacity || '') : '',
            noOfSeatingRows: props.versionData ? (props.versionData.noOfSeatingRows || '') : '',
            bootSpace: props.versionData ? (props.versionData.bootSpace || '') : '',
            fuelTankCapacity: props.versionData ? (props.versionData.fuelTankCapacity || '') : '',

            buttonLoader: false,

            brandOptions: []
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value });
    }

    onSaveVersion = () => {
        const { version, price, summary, engine, engineType, fuelType, maxPower, maxTorque, mileage, driveTrain, transmission, drivingRange, emissionStandard,
            length, width, height, wheelBase, kerbWeight,
            doors, seatingCapacity, noOfSeatingRows, bootSpace, fuelTankCapacity
        } = this.state;
        const validateFields = ['version', 'price', 'summary', 'engine', 'engineType', 'fuelType', 'maxPower', 'maxTorque', 'mileage', 'driveTrain', 'transmission', 'drivingRange', 'emissionStandard',
            'length', 'width', 'height', 'wheelBase', 'kerbWeight',
            'doors', 'seatingCapacity', 'noOfSeatingRows', 'bootSpace', 'fuelTankCapacity'
        ];
        let flag = true;
        validateFields.map((data) => {
            if (this[data] && this[data].error) {
                flag = false;
            }
        })
        if (flag) {
            const carId = this.props.carId;
            const versionKey = convertToKey(version);
            const obj = {
                carId, version, versionKey, price, summary, engine, engineType, fuelType, maxPower, maxTorque, mileage, driveTrain, transmission, drivingRange, emissionStandard,
                length, width, height, wheelBase, kerbWeight,
                doors, seatingCapacity, noOfSeatingRows, bootSpace, fuelTankCapacity
            }
            if (this.props.versionData) {
                updateCarVersion(this.props.versionData.id, obj).then((ver) => {
                    if (ver) {
                        this.props.onCancel(ver, 'update')
                    }
                })
            } else {
                addCarVersion(obj).then((ver) => {
                    if (ver) {
                        this.props.onCancel(ver, 'add')
                    }
                })
            }

        } else {
            this.setState({ isSubmit: true })
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <InputBox label={'Version Name'} refs={(ref) => this.version = ref} id="version" value={this.state.version} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="form-fields">
                        <InputBox label={'Price'} refs={(ref) => this.price = ref} id="price" value={this.state.price} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-fields">
                        <InputBox label={'Summary'} refs={(ref) => this.summary = ref} id="summary" value={this.state.summary} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-sub-heading">Engine & Transmission</div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Engine'} refs={(ref) => this.engine = ref} id="engine" value={this.state.engine} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Engine Type'} refs={(ref) => this.engineType = ref} id="engineType" value={this.state.engineType} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Fuel Type'} refs={(ref) => this.fuelType = ref} id="fuelType" value={this.state.fuelType} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Max Power'} refs={(ref) => this.maxPower = ref} id="maxPower" value={this.state.maxPower} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Max Torque'} refs={(ref) => this.maxTorque = ref} id="maxTorque" value={this.state.maxTorque} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Mileage'} refs={(ref) => this.mileage = ref} id="mileage" value={this.state.mileage} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Drive Train'} refs={(ref) => this.driveTrain = ref} id="driveTrain" value={this.state.driveTrain} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Transmission'} refs={(ref) => this.transmission = ref} id="transmission" value={this.state.transmission} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Driving Range'} refs={(ref) => this.drivingRange = ref} id="drivingRange" value={this.state.drivingRange} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Emission Standard'} refs={(ref) => this.emissionStandard = ref} id="emissionStandard" value={this.state.emissionStandard} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-sub-heading">Dimensions & Weight</div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Length'} refs={(ref) => this.length = ref} id="length" value={this.state.length} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'width'} refs={(ref) => this.width = ref} id="width" value={this.state.width} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Height'} refs={(ref) => this.height = ref} id="height" value={this.state.height} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Wheel Base'} refs={(ref) => this.wheelBase = ref} id="wheelBase" value={this.state.wheelBase} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Kerb Weight'} refs={(ref) => this.kerbWeight = ref} id="kerbWeight" value={this.state.kerbWeight} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="form-sub-heading">Capacity</div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Doors'} refs={(ref) => this.doors = ref} id="doors" value={this.state.doors} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Seating Capacity'} refs={(ref) => this.seatingCapacity = ref} id="seatingCapacity" value={this.state.seatingCapacity} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'No Of Seating Rows'} refs={(ref) => this.noOfSeatingRows = ref} id="noOfSeatingRows" value={this.state.noOfSeatingRows} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Boot Space'} refs={(ref) => this.bootSpace = ref} id="bootSpace" value={this.state.bootSpace} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className="form-fields">
                        <InputBox label={'Fuel Tank Capacity'} refs={(ref) => this.fuelTankCapacity = ref} id="fuelTankCapacity" value={this.state.fuelTankCapacity} onChangeText={this.onChangeText} isSubmit={this.state.isSubmit} />
                    </div>
                </Col>


                <ModalBoxFooter loader={this.state.buttonLoader} onOk={this.onSaveVersion} onCancel={() => this.props.onCancel()} />
            </Row>
        );
    }
};

export default VersionForm; 
