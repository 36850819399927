import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import setAuthorizationToken from "../Actions/SetAuthorizationToken";
import { getLocalStorage } from "../Util/Util";
import Home from "../Screens/LandingPage/Home";
import AdminLogin from "../Screens/Admin/AdminLogin/AdminLogin";
import Layout from "../Layout/Layout";
import NewCars from "../Screens/NewCars/NewCars";
import CarList from "../Screens/NewCars/CarList";
import CarDetails from "../Screens/NewCars/CarDetails";
import CarAllDetails from "../Screens/NewCars/CarAllDetails";
import CarImages from "../Screens/NewCars/CarImages";
import TermsAndConditions from "../Screens/policy/TermsAndConditions";
import PrivacyPolicy from "../Screens/policy/PrivacyPolicy";
import AboutUs from "../Screens/AboutUs/AboutUs";
import CarDetail from "../Screens/LandingPage/ChooseStyle/CarDetails/CarDetails";
import FeaturedCars from "../Screens/LandingPage/FeaturedCars/FeaturedCars";

class CommonRoutes extends React.Component {
  constructor() {
    super();
    setAuthorizationToken(getLocalStorage("UNAUTHENTICATED"));
  }

  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} render={(props) => <Home {...props} />} />
          <Route exact path={`/admin`} component={AdminLogin} />
          <Route
            exact
            path="/TermsAndConditions"
            component={TermsAndConditions}
          />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/newcars" component={NewCars} />
          <Route exact path="/cardetails" component={CarDetail} />
          <Route exact path="/featured-cars" component={FeaturedCars} />
          <Route exact path="/newcars/:brand" component={CarList} />
          <Route exact path="/newcars/:brand/:name" component={CarDetails} />
          <Route
            exact
            path="/newcars/:brand/:name/:version"
            component={CarAllDetails}
          />
          <Route
            exact
            path="/newcars/:brand/:name/images/:type"
            component={CarImages}
          />
        </Switch>
      </Layout>
    );
  }
}

export default CommonRoutes;
