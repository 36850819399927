import React from 'react';
import { connect } from "react-redux";
import { Row, Col, Space } from 'antd';
import ButtonBox from '../../../../Components/ButtonBox/ButtonBox';
import TableBox from '../../../../Components/TableBox/TableBox';
import ModalBox from '../../../../Components/ModalBox/ModalBox';
import BrandForm from './BrandForm'
import { getBrands } from '../../../../Actions/UserAction'
import { getImageUrl } from '../../../../Actions/ActionUrl'

class Brands extends React.Component {
    constructor() {
        super();
        this.state = {
            brands: [],
            open: false
        }
        this.getBrandsData();
    }

    getBrandsData = () => {
        getBrands().then((brands) => {
            if (brands) {
                this.setState({ brands });
            }
        })
    }

    onCancel = (brand, action) => {
        if (brand) {
            const brands = action === 'add' ? [brand, ...this.state.brands] : this.state.brands.map(val => val.id === brand.id ? brand : val)
            this.setState({ open: false, brands });
        } else {
            this.setState({ open: false });
        }
    }

    render() {
        const columns = [
            {
                title: 'Logo',
                dataIndex: 'logo',
                key: 'logo',
                render: logo => logo ? <img src={getImageUrl(logo.path)} style={{ height: 50, width: 50 }} /> : '',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a onClick={() => this.setState({ open: record })}>Edit</a>
                    </Space>
                ),
            },
        ];
        return (
            <React.Fragment>
                <ButtonBox onClick={() => this.setState({ open: true })}>Add</ButtonBox>
                <TableBox columns={columns} dataSource={this.state.brands} />
                <ModalBox title="Brand" visible={this.state.open ? true : false} onCancel={() => this.onCancel()} footer={null} destroyOnClose={true}>
                    <BrandForm onCancel={this.onCancel} brandData={this.state.open ? this.state.open.name ? this.state.open : false : false} />
                </ModalBox>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.users.userInfo,
    };
}

export default connect(mapStateToProps)(Brands);
