import React from "react";
import { Row, Col, Button} from "antd";
import "./CarDetails.scss";
import CarFetures from "./CarFetures";

const CarDetails = () => {
  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <Col flex="auto">
          <img
            alt="example"
            src="https://stimg.cardekho.com/images/carexteriorimages/930x620/Lamborghini/Aventador/6721/Lamborghini-Aventador-SVJ/1621849426405/front-left-side-47.jpg"
         className="cardImg"/>
        </Col>
        <Col flex="370px" style={{ padding: "10px" }}>
          <h1>Lamborghini-Aventador-SVJ</h1>
          <p>TDI Technology Pack</p>
          <div className="c-specific">
            <p>33,819 Kms </p>
            <p>Diesel </p>
            <p>Automatic </p>
            <p>KA01</p>
          </div>
          <div>
            <Row
            //   gutter={[
            //     { xs: 8, sm: 20, md: 30, lg: 40 },
            //     { xs: 8, sm: 16, md: 10, lg: 10 },
            //   ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                style={{
                  backgroundColor: "#dcdce2a1",
                  borderRadius: "10px",
                  padding: "5px",
                }}
                className="c-details"
              >
                <div>
                  <h1>₹ 6,25,00,000</h1>
                </div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span style={{ color: "red" }}> ✔</span> Buy with Luxidrive Prime{" "}
                  </li>
                  <li>
                    <span style={{ color: "red" }}> ✔</span> Easy financing with
                    our partner banks{" "}
                  </li>
                  <li>
                    <span style={{ color: "red" }}> ✔</span> 250-point Inspected
                    cars with no meter tampering{" "}
                  </li>
                  <li>
                    <span style={{ color: "red" }}> ✔</span> Available for Home
                    Test Drive
                  </li>
                </ul>
                <Button type="primary" size="large" align="center">
                  Book Test Drive
                </Button>
              </Col>
            </Row>
          </div>
          <div className="c-features" style={{padding:"20px 0px"}}>
            <CarFetures />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CarDetails;
