import React, { Component } from 'react';
import { Button } from 'antd';

class ModalBoxFooter extends Component {

    render() {
        const { onOk, okText, onCancel, cancelText, loader } = this.props;
        return (
            <div className="ant-modal-footer" style={{ width: '100%', marginTop: 10,marginBottom: -20, paddingRight: 0}}>
                <Button
                    onClick={onCancel}
                    type={'secondary'} >
                    {cancelText || 'Cancel'}
                </Button>
                <Button
                    onClick={onOk}
                    type={'primary'}
                    loading={loader}>
                    {okText || 'Ok'}
                </Button>
            </div>
        );
    }
};

export default ModalBoxFooter; 
