import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import Brands from '../Brands/Brands';

export default class NewCars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Brands />
                </Col>
            </Row>
        );
    }
}