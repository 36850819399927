import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Rate } from 'antd';
import Slider from "react-slick";
import './NewCars.scss';
import { getCarByBrandAndName, getCarVersions } from '../../Actions/UserAction';
import { getImageUrl } from '../../Actions/ActionUrl';
import { convertPrice } from '../../Util/Util';
import { FileImageOutlined, BgColorsOutlined, VideoCameraOutlined, DatabaseOutlined } from '@ant-design/icons';
import './CarDetails.scss';

const Featurecarssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};


export default class CarDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car: false,
            versions: []
        }
        this.getCarData();
    }

    getCarData = () => {
        const brand = this.props.match.params.brand;
        const name = this.props.match.params.name;
        getCarByBrandAndName(brand, name).then((car) => {
            if (car) {
                this.setState({ car });
                this.getVersionData(car.carId)
            }
        })
    }

    getVersionData = (carId) => {
        getCarVersions(carId).then((versions) => {
            if (versions) {
                this.setState({ versions })
                if (sessionStorage.getItem('version_scroll')) {
                    this.scrollToTargetAdjusted('car-versions');
                    sessionStorage.removeItem('version_scroll')
                }
            }
        })
    }

    scrollToTargetAdjusted = (target) => {
        var element = document.getElementById(target);
        var headerOffset = 0;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    render() {
        const { car, versions } = this.state;
        const carName = car ? `${car.brandName} ${car.name}` : ''
        return (
            <div>
                {car &&
                    <div className="car-detail">
                        <Row gutter={24, 20}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Row className="container car-overview">
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <Slider {...Featurecarssettings}>
                                            <div className="image-holder">
                                                <img style={{ width: '100%' }} src={getImageUrl(car.defaultImage)} alt="Car Title" />
                                            </div>
                                        </Slider>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <div className="car-basic-details">
                                            <h1>{carName}</h1>
                                            <Rate value={3} style={{ color: '#ff5421' }} /> <span style={{ paddingLeft: 10 }}>Write Review</span>
                                            <div className="boldtext">₹ {convertPrice(car.lowestPrice)} - ₹ {convertPrice(car.highestPrice)}</div>
                                            <div>Avg. Ex-Showroom price</div>
                                        </div>
                                        <table className="navigation-list">
                                            <tr>
                                                <td onClick={() => this.props.history.push(`/newcars/${car.brand}/${car.carKey}/images/all`)}>
                                                    <FileImageOutlined style={{ fontSize: 22 }} />
                                                    <div>Images</div>
                                                </td>
                                                <td onClick={() => this.props.history.push(`/newcars/${car.brand}/${car.carKey}/colors`)}>
                                                    <BgColorsOutlined style={{ fontSize: 22 }} />
                                                    <div>Colors</div>
                                                </td>
                                                {/* <td>
                                                    <VideoCameraOutlined style={{ fontSize: 22 }} />
                                                    <div>Videos</div>
                                                </td> */}
                                                <td>
                                                    <DatabaseOutlined style={{ fontSize: 22 }} onClick={() => this.scrollToTargetAdjusted('car-versions')} />
                                                    <div>Versions</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="cars-detailed-card">
                                    <div className="boldtext">Key Specs of {carName}</div>
                                    <Row gutter={24, 20}>
                                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="key-sepcs">
                                                <span>
                                                    <i class="fas fa-car" />
                                                </span>
                                                <div clasName="details">
                                                    <h3>Engine</h3>
                                                    <p>1197 cc</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="key-sepcs">
                                                <span>
                                                 <i class="fas fa-charging-station" />
                                                </span>
                                                <div className="details">
                                                    <h3>BHP</h3>
                                                    <p>88.5 Bhp</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="key-sepcs">
                                                <span>
                                                    <i class="fas fa-cogs" />
                                                </span>
                                                <div clasName="details">
                                                    <h3>Transmission</h3>
                                                    <p>Manual <span>5 variants</span></p>
                                                    <p>Automatic <span>4 variants</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="key-sepcs">
                                                <span>
                                                    <i class="fas fa-car-battery" />
                                                </span>
                                                <div clasName="details">
                                                    <h3>Mileage</h3>
                                                    <p>23.2 - 23.76 kmpl</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="cars-detailed-card">
                                    <div className="boldtext">{carName} Summary</div>
                                    {car.summary}
                                </div>
                                <div className="cars-detailed-card">
                                    <div className="boldtext">{carName} Price Summary</div>\
                                    {car.priceSummary}
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} id="car-versions">
                                <div className="cars-detailed-card">
                                    <div className="boldtext" style={{ padding: '10px 0' }}>{carName} Versions</div>
                                    <div className="suvs-versions">
                                        {versions.map((ver) => {
                                            return <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <Link to={`/newcars/${car.brand}/${car.carKey}/${ver.versionKey}`}>
                                                    <div className="version-list">
                                                        <div className="left">
                                                            <div>{ver.version}</div>
                                                            <div>Sample description</div>
                                                        </div>
                                                        <div className="right">{convertPrice(ver.price)}</div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        })}
                                    </div>
                                </div>
                            </Col>                           
                        </Row>
                    </div>}
            </div>
        );
    }
}