import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../Util/Util";
import {
  Layout,
  Table,
  Tabs,
  Button,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
} from "antd";
import "./Cities.scss";
import InputBox from "../../Components/InputBox/InputBox";
import { getActiveCities, getToken } from "../../Actions/UserAction";

export default class CitiesFields extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="select-field-for-city">
          <h3>Which is your city ?</h3>
          <InputBox
            id="email"
            placeholder="Search SUVS in your favourite Cities.."
            prefix={"fa fa-map-marker-alt"}
          />
        </div>
        <div className="cities-list">
          <Row justify="left" gutter={(15, 15)}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/ahmedabad.svg")}
                    alt="Ahmedabad"
                  />
                  <h6>Ahmedabad</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/bangalore.svg")}
                    alt="Bangalore"
                  />
                  <h6>Bangalore</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/chennai.svg")}
                    alt="Chennai"
                  />
                  <h6>Chennai</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/delhi-ncr.svg")}
                    alt="Delhi NCR"
                  />
                  <h6>Delhi NCR</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/gurgaon.svg")}
                    alt="Gurgaon"
                  />
                  <h6>Gurgaon</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/hyderabad.svg")}
                    alt="Hyderabad"
                  />
                  <h6>Hyderabad</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/jaipur.svg")}
                    alt="Jaipur"
                  />
                  <h6>Jaipur</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/kolkata.svg")}
                    alt="Kolkata"
                  />
                  <h6>Kolkata</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  {/* <img src={require('../../assets/images/cities/mumbai.svg')} alt="Mumbai" /> */}
                  <h6>Mumbai</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/new-delhi.svg")}
                    alt="New Delhi"
                  />
                  <h6>New Delhi</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/noida.svg")}
                    alt="Noida"
                  />
                  <h6>Noida</h6>
                </div>
              </Link>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Link>
                <div className="city-details">
                  <img
                    src={require("../../assets/images/cities/pune.svg")}
                    alt="Pune"
                  />
                  <h6>Pune</h6>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
