import React from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';

class AdminDashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            isSubmit: false
        }
    }

    render() {
        return (
            <React.Fragment>
               <div>Admin dashboard coming soon..</div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.users.userInfo,
    };
}

export default connect(mapStateToProps)(AdminDashboard);
