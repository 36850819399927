import React, { Component } from 'react';
import { Button } from 'antd';

class ButtonBox extends Component {

    componentDidMount() {
        this.props.refs && this.props.refs(this);
    }

    componentWillUnmount() {
        this.props.refs && this.props.refs(null);
    }

    render() {
        const { children, onClick, type, loader, style } = this.props;
        return (
            <Button
                style={style}
                onClick={onClick}
                type={type || 'primary'}
                loading={loader}>
                {children}
            </Button>
        );
    }
};

export default ButtonBox;
