import React from 'react'
import  {  Collapse,List  } from 'antd'


const { Panel } = Collapse;
const text = <List
renderItem={()=> (
  <List.Item >
    <p>Registered</p>
    <div>Content</div>
  </List.Item>
)}
/>;

const CarFetures = () => {
  return (
    <Collapse defaultActiveKey={['']} ghost>
    <Panel header="Features & Specs" key="1">
      <p>{text}</p>
    </Panel>
    <Panel header="Inspection Report & Dent Map" key="2">
      <p>{text}</p>
    </Panel>
  </Collapse>
  )
}

export default CarFetures