export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
}

export const email_validate = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(value) === false
}

export const number_validate = (value) => {
  const pattern = /^[0-9]*$/
  return pattern.test(value) === false
}

export const url_validate = (value) => {
  const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  return pattern.test(value) === false
}

export const convertPrice = (value) => {
  const checkLength = `${value}`.length
  if (checkLength === 6 || checkLength === 7) {
    return `${value / 100000} Lakh`
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}

export const convertToKey = (value) => {
  return value.replaceAll(" ", '_').replaceAll(".", '_').toLowerCase();
}