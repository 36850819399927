import React from 'react';
import './Header.scss';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import { Row, Button, Dropdown, Col, Menu, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import mainlogo from '../../assets/images/logo-main.png';
import ModalBox from '../../Components/ModalBox/ModalBox';
import Cities from '../../Screens/Cities/Cities';
import Login from '../../Screens/Login/Login';

const { SubMenu } = Menu;
export default class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            headerView: false,
            openCity: false,
            openLogin: false
        };
    }

    onCancelCity = () => {
        this.setState({ openCity: false })
    }

    onCancelLogin = () => {
        this.setState({ openLogin: false })
    }

    render() {
        return (
            <div className="main-hearder">
                {/* {this.state.headerView && this.state.headerView 
                    
                } */}
                <Row>
                    <Col xs={{ span: 16, order: 1 }} sm={{ span: 16, order: 1 }} md={{ span: 8, order: 1 }} lg={{ span: 8, order: 1 }}>
                        <div className="logo">
                            <Link to="/"><img src={mainlogo} alt="Steer" /> </Link>
                        </div>
                    </Col>

                    <Col xs={{ span: 8, order: 2 }} sm={{ span: 8, order: 2 }} md={{ span: 16, order: 2 }} lg={{ span: 16, order: 2 }}>
                        <div className="container-menu">
                            <div className="navigation">
                                <Menu mode="horizontal" defaultSelectedKeys={['1']}>
                                    {/* <Menu.Item key="3">New SUV's <Link to="/newcars" /></Menu.Item>
                                    <Menu.Item key="4">Used SUV's <Link to="" /></Menu.Item> */}
                                    <Menu.Item key="1">Home <Link to="/" /></Menu.Item>
                                    <Menu.Item key="2">About Us <Link to="/aboutus" /></Menu.Item>
                                    {/* <SubMenu key="sub1" icon={<UserOutlined />} title="My Account">
                                        <Menu.Item key="7">Login</Menu.Item>
                                        <Menu.Item key="8">Register</Menu.Item>
                                        <Menu.Item key="9">Forgot Password</Menu.Item>
                                    </SubMenu> */}
                                    {/* <Menu.Item key="5">Contact</Menu.Item> */}
                                    {/* <Menu.Item key="6">Shop</Menu.Item>                                     */}
                                </Menu>
                                <div className="action-menus">
                                    {/* <Button type="primary">Buy New Cars</Button>
                                    <Link onClick={() => this.setState({ openCity: true })}><i class="fas fa-map-marker-alt" /></Link> */}
                                    <Link onClick={() => this.setState({ openLogin: true })} ><i class="far fa-user-circle" /></Link>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <ModalBox title="Cities" visible={this.state.openCity ? true : false} footer={false}  width={'700px'} onCancel={this.onCancelCity} destroyOnClose={true}>
                    <Cities {...this.props} onCancel={this.onCancelCity} />
                </ModalBox> */}
                <ModalBox title="Login" visible={this.state.openLogin ? true : false} footer={false} width={'360px'} onCancel={this.onCancelLogin} destroyOnClose={true}>
                    <Login {...this.props} onCancel={this.onCancelLogin} />
                </ModalBox>
            </div>
        );
    }
}