import React from 'react';
import { connect } from "react-redux";
import { Steps, Button, message } from 'antd';
import CarForm from './CarForm';
import ManageVersions from './ManageVersions';
import ManagePictures from './ManagePictures';
import ManageColors from './ManageColors';
import Summary from './Summary';
import { getCarById } from '../../../Actions/UserAction';

const { Step } = Steps;

const steps = [
    {
        title: 'Car Details',
        content: 'First-content',
    },
    {
        title: 'Versions',
        content: 'Second-content',
    },
    {
        title: 'Photos',
        content: 'third-content',
    },
    {
        title: 'Colors',
        content: 'fourth-content',
    },
    // {
    //     title: 'Config',
    //     content: 'Last-content',
    // },
];

class ManageCar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            carData: false,
            carId: props.match.params.carId ? props.match.params.carId : false,
            loader: props.match.params.carId ? true : false
        }
        props.match.params.carId && this.getCarData(props.match.params.carId)
    }

    getCarData = (carId) => {
        getCarById(carId).then((carData) => {
            if (carData) {
                this.setState({ carData, loader: false });
            }
        })
    }

    next = () => {
        this.setState({ current: this.state.current + 1 });
    };

    prev = () => {
        this.setState({ current: this.state.current - 1 });
    };

    onChangeIndex = (current, carData) => {
        if (carData) {
            this.setState({ current, carData, carId: carData.carId });
        } else {
            this.setState({ current });
        }
    }

    render() {
        const { current } = this.state
        return (
            <React.Fragment>
                {this.state.loader ? <div style={{ textAlign: 'center', fontWeight: 600 }}>
                    Loading car details
                </div> :
                    <div style={{ padding: 25 }}>
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className="steps-action">
                            {current === 0 && (
                                <CarForm carData={this.state.carData} carId={this.state.carId} onChangeIndex={this.onChangeIndex} />
                            )}
                            {current === 1 && (
                                <ManageVersions carData={this.state.carData} carId={this.state.carId} onChangeIndex={this.onChangeIndex} />
                            )}
                            {current === 2 && (
                                <ManagePictures carData={this.state.carData} carId={this.state.carId} onChangeIndex={this.onChangeIndex} />
                            )}
                            {current === 3 && (
                                <ManageColors carData={this.state.carData} carId={this.state.carId} onChangeIndex={this.onChangeIndex} {...this.props}/>
                            )}
                            {/* {current === 4 && (
                                <Summary carData={this.state.carData} carId={this.state.carId} onChangeIndex={this.onChangeIndex} />
                            )} */}
                            {/* {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                                Previous
                            </Button>
                        )} */}
                        </div>
                    </div>}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.users.userInfo,
    };
}

export default connect(mapStateToProps)(ManageCar);
