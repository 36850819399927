import AppConfig from '../config';

const Url = {
    API_URL: AppConfig.API_URL
};

export default Url

export const getImageUrl = (path) => {
    return `${AppConfig.API_URL}/${path}`
}

