import React, { Component } from 'react';
import { Modal } from 'antd';
class ModalBox extends Component {

    render() {
        const { visible, title, onOk, onCancel, footer, destroyOnClose, width } = this.props;
        return (
            <div>
                <Modal
                    visible={visible}
                    title={title}
                    onOk={onOk}
                    onCancel={onCancel}
                    footer={footer}
                    destroyOnClose={destroyOnClose}
                    width={width}
                >
                    {this.props.children}
                </Modal>
            </div>
        );
    }
};

export default ModalBox; 
