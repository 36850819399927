import React from "react";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="about">
      <div className="title">
        <p>//About Us</p>
        <h1>LUXIDRIVE PRIME</h1>
      </div>
      <div className="content">
        <p>
          Luxidriveprime is the world's first digital platform for buy and sell
          your favorite pre-owned luxury cars worldwide. Luxidriveprime has
          implemented the latest 3d virtual reality for consumers to get a close
          look of their cars inside out, color selection, interiors, exteriors
          and much more. Luxidriveprime is aiming to have the best inventory to
          serve our customer needs really quick with possible best deals in the
          market.
        </p>
      </div>
      </div>
    </div>
  );
};

export default AboutUs;
