import React from 'react';
import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import Sidemenu from './Sidemenu';
import HeaderNav from './Header/Header';
import FooterNav from './Footer/Footer';

const { Header, Footer, Sider, Content } = Layout;

export default class Layouts extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <HeaderNav />
          </Header>
          <Content
            className="site-layout-background"
            style={{ minHeight: '70vh'}}
          >
            {this.props.children}
          </Content>
          <Footer className="">
            <FooterNav />
          </Footer>
        </Layout>
      </Layout>
    );
  }
}